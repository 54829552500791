<script setup>
import { VueFinalModal } from 'vue-final-modal'
import { useTimeAgo } from '@vueuse/core'
import { Icon } from '@iconify/vue'
import moment from 'moment'

import modals from '../Modals/Helper'
</script>

<template>
  <hr class="divider" :style="{ 'border-color': feature.properties['line-color'] }">
  <div class="text-center tags">
    <span class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded" :title="feature.properties.issued_at">Issued {{ useTimeAgo(feature.properties.issued_at) }}</span>

    <span v-if="moment.utc(feature.properties.expires_at).isBefore(moment.utc())" class="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded" :title="feature.properties.expires_at">Expired {{ useTimeAgo(feature.properties.expires_at) }}!</span>
    <span v-else class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded" :title="feature.properties.expires_at">Expires {{ useTimeAgo(feature.properties.expires_at) }}</span>

    <button type="button" class="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-2.5 py-1 text-center me-2 mb-2" @click="onShareClick($event)">
          <Icon icon="ion:share-outline" class="inline mr-1 size-5" />Share</button>

  </div>
  <pre class="text">{{ feature.properties.text }}</pre>
</template>

<style type="text/css" scoped>
.divider {
  border-width: 2px;
}

.text {
  white-space: pre-wrap;
}
</style>

<script>
export default {
  name: 'MesoscaleDiscussionModal',
  props: ['feature'],
  methods: {
    onShareClick(e) {
      modals.share();
    }
  }
}
</script>

<style type="text/css" scoped>
.divider {
  border-width: 2px;
}

.tags span {
  display: inline-block;
  margin-bottom: 4px;
}

.text {
  white-space: pre-wrap;
}
</style>
