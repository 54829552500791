<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useAppStore } from '@/stores/app'
import MapboxMap from '@/components/MapboxMap.vue'
import Colortable from '@/components/Colortable.vue'
import AppLogo from '@/components/AppLogo.vue'
import Outlooks from '@/components/Outlooks.vue'
import Sidebar from '@/components/Sidebar.vue'
import RadarDrawer from '@/components/RadarDrawer.vue'
import ShareButton from '@/components/ShareButton.vue'
import LocateMeButton from '../components/LocateMeButton.vue'

import RecordingGlowEffect from '@/components/Extra/RecordingGlowEffect.vue'
const isWeatherWiseBridgeDefined = ref(false);
const userbackButtonStyle = computed(() => isWeatherWiseBridgeDefined.value ? '10.5rem' : '7rem');

// Can't use v-bind because the userback element is outside of the app's root element
function updateUserbackButtonStyle() {
  const styleElement = document.createElement('style');
  styleElement.id = 'userback-button-style';
  styleElement.textContent = `
    .userback-button {
      bottom: ${userbackButtonStyle.value} !important;
    }
  `;

  const existingStyle = document.getElementById('userback-button-style');
  if (existingStyle) {
    existingStyle.replaceWith(styleElement);
  } else {
    document.head.appendChild(styleElement);
  }
}

onMounted(() => {
    isWeatherWiseBridgeDefined.value = typeof window.WeatherWiseBridge !== 'undefined';
    updateUserbackButtonStyle();
});

watch(userbackButtonStyle, updateUserbackButtonStyle);

const appStore = useAppStore();
</script>

<template>
  <MapboxMap />
  <Colortable />
  <AppLogo />
  <Sidebar />

  <Outlooks v-if="appStore.isReady() && appStore.mode === 'OUTLOOKS'" />
  <RadarDrawer v-if="appStore.isReady() && appStore.mode === 'RADAR'" />

  <LocateMeButton v-if="isWeatherWiseBridgeDefined" />
  <ShareButton />

  <RecordingGlowEffect />
</template>
