export default /* glsl */`
precision highp float;
precision highp int;
uniform mat4 u_matrix;
attribute vec3 a_pos;
varying vec4 color;

uniform vec4 colormap[512];
uniform float minimum;
uniform float maximum;
uniform float opacity;
uniform float colormap_length;

void main() {
    float value = min(max(a_pos.z, minimum), maximum);
    int colorIndex = int((value - minimum) / (maximum - minimum) * colormap_length);
    color = colormap[colorIndex]/255.0;
    color.a *= opacity;
    gl_Position = u_matrix * vec4(a_pos.xy, 0.0, 1.0);
}
`