import { toRaw } from 'vue'
import moment from 'moment'

export const removeNonActive = (features, issueDatetimeKey = 'issued_at', expiryDatetimeKey = 'expires_at') => {
  const now = moment.utc()

  return features.filter((f) => {
    const issuedAt = moment.utc(f.properties[issueDatetimeKey])
    const expiresAt = moment.utc(f.properties[expiryDatetimeKey])

    // console.log(issuedAt, expiresAt)

    return now.isAfter(issuedAt) && now.isBefore(expiresAt)
  })
}

export const removeExpired = (features, datetimeKey = 'expires_at') => {
  const now = moment.utc()

  return features.filter((f) => {
    const expiresAt = moment.utc(f.properties[datetimeKey])

    return now.isBefore(expiresAt)
  })
}

export const automaticallyRemoveExpired = (store, checkInterval, datetimeKey = 'expires_at') => {
  return setInterval(() => {
    const features = removeExpired(toRaw(store.geojson.features), datetimeKey)
    if (features.length != store.geojson.features) {
      store.geojson.features = features
    }
  }, checkInterval);
}