export default /* glsl */`
precision highp float;
precision highp int;
uniform mat4 u_projection;
uniform mat4 u_globeToMercMatrix;
uniform float u_globeToMercatorTransition;
uniform vec2 u_centerInMercator;
uniform float u_pixelsPerMeterRatio;
attribute vec3 a_pos_merc;
varying vec4 color;

uniform vec4 colormap[512];
uniform float minimum;
uniform float maximum;
uniform float opacity;
uniform float colormap_length;

const float PI = 3.14159265358979323846;

vec2 mercatorToLngLat(vec2 mercator) {
    float x = mercator.x - 0.5;
    float y = mercator.y - 0.5;
    float lng = x * 2.0 * PI;

    // Calculate latitude from Mercator y-coordinate
    float latRad = y * 2.0 * PI; 
    float lat = atan(exp(latRad));
    lat = lat * 2.0 - PI/2.0;

    return vec2(lng, -lat);
}

vec3 lngLatToECEF(vec2 lngLat) {
    float lat = lngLat.y; // Convert degrees to radians
    float cosLat = cos(lat);
    float sinLat = sin(lat);
    float lng = lngLat.x;

    float radius = 8192.0 / PI / 2.0; // Adjusted radius based on Mapbox's definition

    float sx = cosLat * sin(lng) * radius;
    float sy = -sinLat * radius;
    float sz = cosLat * cos(lng) * radius;

    return vec3(sx, sy, sz);
}

void main() {
    float value = min(max(a_pos_merc.z, minimum), maximum);
    int colorIndex = int((value - minimum) / (maximum - minimum) * colormap_length);
    color = colormap[colorIndex]/255.0;
    color.a *= opacity;

    vec4 merc = vec4(a_pos_merc.xy, 0.0, 1.);

    vec2 latLong = mercatorToLngLat(a_pos_merc.xy);
    vec3 ecef = lngLatToECEF(latLong);
    
    vec4 p = u_projection * u_globeToMercMatrix * vec4(ecef.xyz, 1.);
    p /= p.w;
    
    if (u_globeToMercatorTransition > 0.) {
        merc.xy = (merc.xy - u_centerInMercator) * u_pixelsPerMeterRatio + u_centerInMercator;
        merc.z *= u_pixelsPerMeterRatio;

        merc = u_projection * merc;
        merc /= merc.w;
        p = mix(p, merc, u_globeToMercatorTransition);
    }

    gl_Position = p;
}
`