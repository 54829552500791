<script setup>
import { ModalsContainer } from 'vue-final-modal'
import { useHead } from '@unhead/vue'

import { PAGE_TITLE } from '@/brand'
import { isNavigatorStandalone } from '@/logic/Extra/helpers'
import { overrideAPIs } from '@/tools/overrideAPIs.js'

import socket from '@/logic/Socket.js'

overrideAPIs()

// remove any existing listeners (after a hot module replacement)
socket.off()

socket.connect()

useHead({
  title: PAGE_TITLE
})

// Prevent zoom and text selection on mobile
const preventZoomAndTextSelection = () => {
  document.addEventListener('touchstart', (event) => {
    if (event.touches.length > 1) {
      event.preventDefault(); // Prevent zoom
    }
  }, { passive: false });

  document.addEventListener('gesturestart', (event) => {
    event.preventDefault(); // Prevent zoom
  });

  document.addEventListener('selectstart', (event) => {
    event.preventDefault(); // Prevent text selection
  });
};

preventZoomAndTextSelection();
</script>

<script>

export default {
  methods: {
    setStandaloneStyles() {
      const style = document.createElement('style');
      style.textContent = `
        .userback-button { bottom: 10rem !important; }
      `;
      document.head.appendChild(style);
    }
  },
  mounted() {
    if (isNavigatorStandalone()) {
      this.setStandaloneStyles()
    }
  }
}
</script>

<template>
  <RouterView />
  <ModalsContainer />
</template>
