import { defineStore } from 'pinia'

const DEFAULT_MODE = 'RADAR';

export const useAppStore = defineStore('app', {
  state: () => ({
    booted: false,
    mode: (() => {
      const params = new URLSearchParams(window.location.hash.substr(1));

      return params.has('m') ? params.get('m') : DEFAULT_MODE;
    })()
  }),

  actions: {
    ready() {
      this.booted = true
    },

    isReady() {
      return this.booted
    },

    setMode(mode) {
      this.hideAllModes()

      this.mode = mode;

      this.showMode()

      const params = new URLSearchParams(window.location.hash.substr(1));
      params.set('m', this.mode)

      // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
      window.location.hash = params.toString().replaceAll('%2F', '/')
    },

    hideAllModes() {
      for (const feature of ['radar', 'outlooks']) {
        window.map[feature].hide();
      }
    },

    showMode() {
      if(this.mode === 'RADAR') {
        window.map.radar.show();
      }
      else if(this.mode === 'OUTLOOKS') {
        window.map.outlooks.show();
      }
    }
  }
})
