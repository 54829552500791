import { toRaw } from 'vue'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'

import { stopPropagation, debounce } from '@/tools/mapbox-map'

import socket from '@/logic/Socket'
import api from '@/logic/Api'
import SimpleModal from './Modals/Templates/Simple.vue'
import OutlookDiscussionModal from './Outlooks/Discussion.vue'
import OutlookHelpModal from './Outlooks/OutlookHelp.vue'

export default class Outlooks {
  constructor(map) {
    this.map = map

    this.sourceNonHatchedId = 'outlooks-non-hatched-source'
    this.sourceHatchedId = 'outlooks-hatched-source'
    
    this.lineNonHatchedLayerId = 'outlooks-line-non-hatched-layer'
    this.fillNonHatchedLayerId = 'outlooks-fill-non-hatched-layer'
    
    this.lineHatchedLayerId = 'outlooks-line-hatched-layer'
    this.fillHatchedLayerId = 'outlooks-fill-hatched-layer'

    this.activeSocketRooms = [];
    this.renderedGeojson = null;

    this.addLayer()

    let bufferedFeatures = [];
    const onPolygonDebouncedClick = debounce((e) => {
      const caretSvg = `<svg class='inline size-4 h-full ml-1' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="m184.49 136.49l-80 80a12 12 0 0 1-17-17L159 128L87.51 56.49a12 12 0 1 1 17-17l80 80a12 12 0 0 1-.02 17"/></svg>`;
      const titleHtml = bufferedFeatures.map(f => `<div><strong>${f.properties.LABEL2}</strong></div>`).join('');

      const div = window.document.createElement('div');
      div.innerHTML = `<div class='flex cursor-pointer'><div>${titleHtml}</div><div>${caretSvg}</div></div>`;
      div.addEventListener('click', () => {
        this.map.popups.clear();

        if(this.renderedGeojson === null) return false;

        this.openDiscussionModel('Forecast Discussion', this.renderedGeojson.metadata);
      });

      const p = this.map.popups.create()
        .setLngLat(e.lngLat)
        .setDOMContent(div);

      this.map.popups.render(p);

      bufferedFeatures = [];
    }, 50);

    const onPolygonClick = ((e) => {
      if(e.features.length == 0) return;
      // console.log(e.features);

      const validFeatures = e.features.filter(f => f.source.startsWith('outlooks-'));

      bufferedFeatures = bufferedFeatures.concat(validFeatures);

      onPolygonDebouncedClick(e);
    });

    map.on('click', this.fillHatchedLayerId, onPolygonClick)
    map.on('click', this.fillNonHatchedLayerId, onPolygonClick)

    map.loadImage(
      'other/dashed-hatch.png',
      (err, image) => {
        // Throw an error if something goes wrong.
        if (err) throw err;

        // Add the image to the map style.
        map.addImage('pattern', image);
    });

    this.hide()
  }

  addLayer() {
    this.map.addSource(this.sourceHatchedId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addSource(this.sourceNonHatchedId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addLayer({
      id: this.lineHatchedLayerId,
      type: 'line',
      source: this.sourceHatchedId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, "land-structure-polygon")

    this.map.addLayer({
      id: this.fillHatchedLayerId,
      type: 'fill',
      source: this.sourceHatchedId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
        'fill-pattern': ['get', 'fill-pattern']
      }
    }, this.lineHatchedLayerId)

    this.map.addLayer({
      id: this.lineNonHatchedLayerId,
      type: 'line',
      source: this.sourceNonHatchedId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, this.fillHatchedLayerId)

    this.map.addLayer({
      id: this.fillNonHatchedLayerId,
      type: 'fill',
      source: this.sourceNonHatchedId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
        // 'fill-pattern': ["coalesce", ['get', 'fill-pattern'], null]
      }
    }, this.lineNonHatchedLayerId)
  }

  async fetchOutlook(id, step) {
    const geojson = await api.instance().get(`/outlooks/${id}/${step}/latest.geojson`);

    return geojson;
  }

  async renderOutlook(id, step) {
    this.stopListeningToRooms();

    const geojson = await this.fetchOutlook(id, step);

    // A quick hack...
    const hatched = { ...geojson}
    hatched.features = geojson.features.filter(f => f.properties.LABEL === 'SIGN').map(f => {
      if(f.properties.LABEL === 'SIGN') {
        f.properties['DN'] = 1000;
        f.properties['fill'] = "rgba(0,0,0,0)";

        f.properties['fill-pattern'] = 'pattern';
      }
      f.properties['line-sort-key'] = f.properties['DN'];
      f.properties['fill-sort-key'] = f.properties['DN'];
      f.properties['line-color'] = f.properties['stroke'];
      f.properties['fill-color'] = f.properties['fill'];
      f.properties['line-opacity'] = 1;
      f.properties['fill-opacity'] = 1;
      f.properties['line-width'] = 2;

      return f;
    });

    const nonHatched = { ...geojson};
    nonHatched.features = geojson.features.filter(f => f.properties.LABEL !== 'SIGN').map(f => {
      if(f.properties.LABEL === 'SIGN') {
        f.properties['DN'] = 1000;
        f.properties['fill'] = "rgba(0,0,0,0)";

        // f.properties['fill-pattern'] = 'pattern';
      }
      f.properties['line-sort-key'] = f.properties['DN'];
      f.properties['fill-sort-key'] = f.properties['DN'];
      f.properties['line-color'] = f.properties['stroke'];
      f.properties['fill-color'] = f.properties['fill'];
      f.properties['line-opacity'] = 1;
      f.properties['fill-opacity'] = 1;
      f.properties['line-width'] = 2;

      return f;
    });

    this.map.getSource(this.sourceHatchedId).setData(hatched)
    this.map.getSource(this.sourceNonHatchedId).setData(nonHatched)

    this.renderedGeojson = geojson;

    // console.log(geojson);

    const room = `outlook:${id}/${step}`;
    this.activeSocketRooms.push(room)
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Outlook update', room, data)

      await this.renderOutlook(id, step);
    });

    return geojson;
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  clear() {
    this.stopListeningToRooms();

    this.map.getSource(this.sourceNonHatchedId).setData({
      type: 'FeatureCollection',
      features: []
    })
  }

  openDiscussionModel(title, metadata) {
    useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: title
      },
      slots: {
        default: useModalSlot({
          component: OutlookDiscussionModal,
          attrs: {
            metadata
          }
        })
      },
    });
  }

  openOutlookHelpModal(title, text) {
    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title
      },
      slots: {
        default: useModalSlot({
          component: OutlookHelpModal,
          attrs: {
            text,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  show() {
    for(const layerId of [this.lineNonHatchedLayerId, this.fillNonHatchedLayerId, this.lineHatchedLayerId, this.fillHatchedLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of [this.lineNonHatchedLayerId, this.fillNonHatchedLayerId, this.lineHatchedLayerId, this.fillHatchedLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }
}
