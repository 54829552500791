import { toRaw } from 'vue'
import mapboxgl from 'mapbox-gl'
import { colord } from 'colord'
import { centroid, point, bearing, destination, bbox } from '@turf/turf'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'

import { stopPropagation } from '../tools/mapbox-map'

import nwsMDConfig from '../data/nws_mesoscale_discussion_config.js'

import { useMesoscaleDiscussionsStore } from '../stores/mesoscale_discussions'

import socket from '../logic/Socket'
import api from '../logic/Api'
import SimpleModal from './Modals/Templates/Simple.vue'
import CenteredModal from './Modals/Templates/Centered.vue'
import MDModal from './MesoscaleDiscussions/Modal.vue'
import MDNotFoundModal from './MesoscaleDiscussions/NotFound.vue'
import MDHelpModal from './MesoscaleDiscussions/MDHelp.vue'
import MDHelpModalTitle from './MesoscaleDiscussions/MDHelpTitle.vue'

export default class MesoscaleDiscussions {
  constructor(map) {
    this.map = map

    this.mesoscaleDiscussionsStore = useMesoscaleDiscussionsStore()

    // Initialise the store
    this.mesoscaleDiscussionsStore.init()

    this.sourceId = 'mesoscale-discussions-source'
    this.lineLayerId = 'mesoscale-discussions-line-layer'
    this.fillLayerId = 'mesoscale-discussions-fill-layer'

    this.addLayer()

    const MDOnClick = stopPropagation((e) => {
      if(e.features.length == 0) return;
      console.log(e.features);

      let feature = this.mesoscaleDiscussionsStore.geojson.features.find(f => f.properties.id === e.features[0].properties.id);
      if(feature === undefined) return;
      feature = toRaw(feature)

      console.log(feature)

      this.openModal(feature)
    });

    map.on('click', this.fillLayerId, MDOnClick)

    // Subscribe to updates
    this.mesoscaleDiscussionsStore.$subscribe((mutation, state) => {
      const geojson = toRaw(state.geojson)

      this.render(geojson)
    })

    // Render mesoscale discussions already in the store (cached offline)
    this.render(toRaw(this.mesoscaleDiscussionsStore.geojson));

    // Request latest mesoscale discussions
    (async () => {
      await this.mesoscaleDiscussionsStore.load()
      
      // TODO
      // Refactor code handling params stored in the URL
      
      // Open md from the url
      // After we've loaded the latest mds
      const params = new URLSearchParams(window.location.hash.substr(1));
      if(params.has('mdid')) {
        const id = params.get('mdid');

        const feature = this.mesoscaleDiscussionsStore.geojson.features.find(f => f.properties.id === id);

        if(feature !== undefined) {
          if(params.has('mdr') && params.get('mdr') == 1) {
            this.fitBounds(feature);
              
            setTimeout(() => {
              const center = centroid(feature.geometry);
              // Don't need to add await here
              this.map.radar.turnOnClosestOnlineRadar(center);

              const params = new URLSearchParams(window.location.hash.substr(1));
              params.delete('mdr')

              // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
              window.location.hash = params.toString().replaceAll('%2F', '/')
            }, 500);
          }

          return this.openModal(feature);
        }

        // Warning is no longer active...

        // Try and load from the archive
        await this.fetchFromArchive(id)
      }
    })();

    // Subscribe to mesoscale discussions events
    socket.roomJoin('mesoscale-discussions')

    socket.on('mesoscale-discussions', async (data) => {
      console.log('Mesoscale discussion update', data)

      // TODO
      // Improve this...
      // Atm, we'll just make an AJAX request to fetch all the mesoscale discussions when there is an update
      // In the future, let's use the 'data' here to manipulate our local list
      await this.mesoscaleDiscussionsStore.load()
    })
  }

  addLayer() {
    this.map.addSource(this.sourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addLayer({
      id: this.lineLayerId,
      type: 'line',
      source: this.sourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, window.map.warnings.fillLayerId)

    this.map.addLayer({
      id: this.fillLayerId,
      type: 'fill',
      source: this.sourceId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
      }
    }, this.lineLayerId)
  }

  applyPropertiesToFeature(f) {
    const config = nwsMDConfig[f.properties.product]

    f.properties['line-color'] = config.color
    f.properties['line-background-color'] = config.color
    f.properties['line-opacity'] = 1
    f.properties['line-width'] = 2;
    f.properties['line-sort-key'] = 1000 - config.priority

    f.properties['fill-color'] = config.color
    f.properties['fill-opacity'] = 0.1;
    f.properties['fill-sort-key'] = 1000 - config.priority
    return f
  }

  render(geojson) {
    const features = geojson.features.map((f) => {
      return this.applyPropertiesToFeature(f);
    })

    this.map.getSource(this.sourceId).setData({
      type: 'FeatureCollection',
      features: features
    })
  }

  openMDHelpModal(code) {
    const config = nwsMDConfig[code]

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: config.name
      },
      slots: {
        title: useModalSlot({
          component: MDHelpModalTitle,
          attrs: {
            config,
            onClose() {
              modal.close()
            },
          }
        }),
        default: useModalSlot({
          component: MDHelpModal,
          attrs: {
            config,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  openModal(feature) {
    const config = nwsMDConfig[feature.properties.product]

    useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: `${config.name} #${feature.properties.number}`,
        onOpened() {
          const params = new URLSearchParams(window.location.hash.substr(1));
          params.set('mdid', feature.properties.id)

          // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
          window.location.hash = params.toString().replaceAll('%2F', '/')
        },
        onClosed() {
          const params = new URLSearchParams(window.location.hash.substr(1));
          params.delete('mdid')

          // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
          window.location.hash = params.toString().replaceAll('%2F', '/')
        },
      },
      slots: {
        default: useModalSlot({
          component: MDModal,
          attrs: {
            feature: feature,
          }
        })
      },
    });
  }

  openNotFoundModal() {
    return useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'Mesoscale Discussion Not Found',
      },
      slots: {
        default: useModalSlot({
          component: MDNotFoundModal
        })
      },
    })
  }

  async fetchFromArchive(id) {
    try {
      const geojson = await api.instance().get(`/mesoscale-discussions/archive/${id}.geojson`);
      const feature = this.applyPropertiesToFeature(geojson);

      return this.openModal(feature);
    } catch (error) {
      console.log(error)
      
      this.openNotFoundModal();
    }
  }

  show() {
    for(const layerId of [this.lineLayerId, this.fillLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of [this.lineLayerId, this.fillLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }

  fitBounds(feature) {
    const box = bbox(feature.geometry)

    const sw = new mapboxgl.LngLat(box[0], box[1]);
    const ne = new mapboxgl.LngLat(box[2], box[3]);
    const llb = new mapboxgl.LngLatBounds(sw, ne);

    this.map.fitBounds(llb, {
      padding: window.innerWidth / 8,
      duration: 0
    })
  }
}
