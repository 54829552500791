<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<template>
  <SimpleColortable v-if="active && colortable.length > 0" :colortable="colortable" />

  <div id="outlooks-bar" class="absolute top-16 mt-2 left-3 sm:left-1/2 sm:-translate-x-1/2 w-[100] z-20 rounded-lg flex select-none" v-if="active">
    <div class="bg-white rounded-lg p-1">
      <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 inline p-1" :class="{
        'max-w-28 sm:max-w-full': activeOutlook !== '0'
      }" v-model="activeOutlook" @change="onOutlookChange($event)">
        <option value="0" selected>Choose outlook...</option>
        <optgroup label="SPC Convective/Severe Weather">
          <option value="USA/SPC/CONVECTIVE/CATEGORICAL">Categorical</option>
          <option value="USA/SPC/CONVECTIVE/TORNADO">Tornado</option>
          <option value="USA/SPC/CONVECTIVE/WIND">Wind</option>
          <option value="USA/SPC/CONVECTIVE/HAIL">Hail</option>
          <option value="USA/SPC/CONVECTIVE/PROB">Probabilistic</option>
        </optgroup>
        <!-- <optgroup label="SPC Thunderstorms">
          <option value="USA/SPC/THUNDERSTORMS/THUNDERSTORMS">Thunderstorms</option>
        </optgroup> -->
        <!-- <optgroup label="SPC Fire Weather">
          <option value="USA/SPC/FIRE/DRYT">Dry Thunderstorms</option>
          <option value="USA/SPC/FIRE/WINDRH">Wind/RH</option>
        </optgroup> -->
      </select>
      <template v-if="activeOutlook !== '0'">
        <a href="#" class="ml-2 inline" @click.prevent="showHelp()"><Icon icon="carbon:help" class="size-5 inline" /></a>
        
        <span>
          <button v-for="step in steps.slice(0, steps.length <= maxStepOptions ? maxStepOptions : maxStepOptions-1)" v-text="step.name" class="hover:text-gray-950 hover:bg-gray-200 font-bold py-1.5 px-3 ml-2 rounded-full text-xs" :class="{
              'bg-blue-600 hover:bg-blue-600 text-white hover:text-black': step.id === activeStep
            }" @click.prevent="onTimestepChange($event, step)"></button>
          
          <template v-if="steps.length > maxStepOptions">
            <span class="relative" v-on-click-outside="onCloseMore">
              <button class="hover:text-gray-950 hover:bg-gray-200 font-bold py-1.5 px-3 ml-2 rounded-full text-xs" :class="{
                'bg-blue-600 hover:bg-blue-600 text-white hover:text-white': steps.slice(maxStepOptions-1).find(s => s.id === activeStep) !== undefined
              }" @click.prevent="onMoreClick">More..</button>

              <div v-if="moreDropdownActive" class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-20 right-0 top-9 absolute">
                <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
                  <li v-for="step in steps.slice(maxStepOptions-1)">
                    <a href="#" class="block px-4 py-2 font-bold text-xs" :class="{
                      'bg-blue-600 hover:bg-blue-600 text-white hover:text-white': step.id === activeStep,
                      'hover:bg-gray-100': step.id !== activeStep
                    }" v-text="step.name" @click.prevent="onTimestepChange($event, step)"></a>
                  </li>
                </ul>
              </div>
            </span>
          </template>
        </span>
        
        <a href="#" class="ml-2 inline" @click.prevent="share()"><Icon icon="ion:share-outline" class="size-5 inline" /></a>
      </template>
      
      <!-- <a href="#" class="ml-2 inline" @click.prevent="close()"><Icon icon="clarity:window-close-line" class="size-6 inline" /></a> -->
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import { colord } from 'colord'

import SimpleColortable from '@/components/SimpleColortable.vue'

import modals from '@/logic/Modals/Helper'

const help = {
  'USA/SPC/CONVECTIVE': {
    title: 'SPC Convective/Severe Weather',
    text: `The Storm Prediction Center (SPC) provides Day 1, Day 2, and Day 3 Convective Outlooks that highlight both non-severe thunderstorm regions and severe thunderstorm risks across the contiguous U.S. These probabilistic forecasts reflect the likelihood of severe weather occurring within 25 miles of any given point.

The categorical risk levels in these outlooks are based on probability forecasts for tornadoes, damaging winds, and large hail on Days 1 and 2, and a combined severe weather risk for Day 3.

- TSTM (light green): General or non-severe thunderstorms – Indicates areas with a 10% or higher probability of thunderstorms during the forecast period.
- 1-MRGL (dark green): Marginal risk – Indicates a chance of severe storms that are either limited in organization and duration or very low in coverage and intensity.
- 2-SLGT (yellow): Slight risk – Highlights areas of organized severe storms that are not widespread but can vary in intensity.
- 3-ENH (orange): Enhanced risk – Denotes a higher coverage of severe storms compared to Slight risk, with varying intensity levels.
- 4-MDT (red): Moderate risk – Forecasts widespread severe weather, including several tornadoes or numerous intense thunderstorms. This level is typically used for days with intense supercells producing tornadoes or large hail, or for widespread damaging winds.
- 5-HIGH (magenta): High risk – Signals a severe weather outbreak is likely, including numerous long-lived, intense tornadoes or a derecho with hurricane-force winds and widespread damage. This level is reserved for extreme confidence in severe weather outbreaks.

Forecasting severe weather events, such as tornadoes, large hail, or damaging winds, involves significant uncertainty. It's essential not to rigidly associate the risk category (e.g., 2-SLGT-yellow) with the expected intensity of every storm in the area. Violent tornadoes, for instance, can occur in 2-SLGT-yellow, 3-ENH-orange, or 4-MDT-red risk zones, not just in 5-HIGH-magenta. Some 2-SLGT-yellow areas may cover large regions with only a few tornadoes expected, while others may pose no tornado threat but still bring severe hail and wind.

SPC outlooks focus on organized convection, including supercells, squall lines, and multicell thunderstorms, all of which can cause damage from tornadoes, high winds, or large hail. Pulse-type thunderstorms, which are isolated and brief, are not considered organized and are typically excluded from severe risk areas unless confidence is high enough to designate a 1-MRGL-dark green risk. While any thunderstorm can briefly produce severe weather, it's not uncommon for local National Weather Service offices to issue severe thunderstorm warnings outside of an SPC risk zone.`
  },
  
  'USA/SPC/FIRE': {
    title: 'SPC Fire Weather',
    text: `The Storm Prediction Center (SPC) provides the Fire Weather Outlooks and are designed to identify areas across the continental U.S. where existing fuel conditions, combined with forecasted weather over the next 8 days, pose a significant risk for the ignition and spread of wildfires. This product is used by the National Weather Service (NWS) and various federal, state, and local agencies.

Each outlook includes a categorical forecast that visually represents fire weather risk areas across the U.S., along with a detailed text narrative. The graphic uses different labels and colors to depict five types of Fire Weather Outlook risk areas:

- ELEVATED (orange): Elevated risk due to wind and relative humidity.
- CRITICAL (red): Critical risk from wind and relative humidity.
- EXTREME (magenta): Extremely critical risk from wind and relative humidity.
- ISODRYT (brown): Elevated risk from isolated dry thunderstorms.
- SCTDRYT (red): Critical risk from scattered dry thunderstorms.

Guidelines for issuing Critical and Extremely Critical areas are as follows:

Critical risk from dry thunderstorms:

- Dry fuels (as defined below).
- At least 40% coverage of cloud-to-ground lightning strikes, with rainfall of 0.10" or less.
- Relative humidity at or below regional thresholds (refer to this graphic).
- Temperatures at or above 50-60°F, depending on the season.

Critical risk from wind and relative humidity:

- Dry fuels.
- Sustained winds of 20 mph or greater (15 mph in Florida).
- Relative humidity at or below regional thresholds.
- Temperatures at or above 50-60°F, depending on the season.
- These conditions must persist for 3 hours or more.

Extremely Critical risk from wind and relative humidity:

- Very dry fuels.
- Sustained winds of 30 mph or greater (25 mph in Florida).
- Relative humidity at or below two-thirds of regional thresholds.
- Temperatures at or above 60-70°F, depending on the season.
- These conditions must persist for 3 hours or more.

Extremely Critical areas are designated when wind, humidity, and temperatures significantly deviate from typical norms. They may also be issued for borderline weather conditions if exceptional drought is present.`
  }
}

const timesteps = {
  // Convective/severe
  'USA/SPC/CONVECTIVE/CATEGORICAL': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
    {
      id: 'DAY_3',
      name: 'Day 3'
    },
  ],
  'USA/SPC/CONVECTIVE/TORNADO': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  'USA/SPC/CONVECTIVE/WIND': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  'USA/SPC/CONVECTIVE/HAIL': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  'USA/SPC/CONVECTIVE/PROB': [
    // {
    //   id: 'DAY_3',
    //   name: 'Day 3'
    // },
    {
      id: 'DAY_4',
      name: 'Day 4'
    },
    {
      id: 'DAY_5',
      name: 'Day 5'
    },
    {
      id: 'DAY_6',
      name: 'Day 6'
    },
    {
      id: 'DAY_7',
      name: 'Day 7'
    },
    {
      id: 'DAY_8',
      name: 'Day 8'
    },
  ],
  // Fire weather
  'USA/SPC/FIRE/DRYT': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
  'USA/SPC/FIRE/WINDRH': [
    {
      id: 'DAY_1',
      name: 'Day 1'
    },
    {
      id: 'DAY_2',
      name: 'Day 2'
    },
  ],
};

export default {
  name: 'Outlooks',
  components: {
    Icon,
    SimpleColortable
  },
  data() {
    return {
      active: true,
      moreDropdownActive: false,
      activeOutlook: '0',
      steps: [],
      activeStep: null,
      maxStepOptions: 3,
      colortable: []
    };
  },
  async mounted() {
    const params = new URLSearchParams(window.location.hash.substr(1));
    if(params.has('oid') && params.has('ost')) {
      const id = params.get('oid');
      const step = params.get('ost');

      this.active = true
      this.activeOutlook = id
      this.activeStep = step

      await this.onOutlookChange();
    }
  },
  unmounted() {
    this.clear();
  },
  methods: {
    async onOutlookChange(e) {
      this.reset()

      const steps = timesteps[this.activeOutlook];

      if(steps === undefined) {
        this.clear();
        return;
      }

      // Check if the outlook has a step
      // that is the same as the active step
      const hasStep = steps.find(s => s.id === this.activeStep) !== undefined;
      if(! hasStep) {
        this.activeStep = steps[0].id
      }

      this.steps = steps;

      this.setUrlHash();

      // Load data...
      const geojson = await window.map.outlooks.renderOutlook(this.activeOutlook, this.activeStep)

      this.setColortableBasedOnGeojson(geojson)
    },
    async onTimestepChange(e, step) {
      this.reset();

      this.activeStep = step.id

      this.setUrlHash();

      // Load data...
      const geojson = await window.map.outlooks.renderOutlook(this.activeOutlook, this.activeStep)

      this.setColortableBasedOnGeojson(geojson)
    },
    setColortableBasedOnGeojson(geojson) {
      const colortable = geojson.features.filter(f => {
        if(typeof f.properties.fill !== 'string') return false;

        return f.properties.fill.length > 0;
      }).map(f => {
        const rgb = colord(f.properties.fill).toRgb();

        let name = f.properties.LABEL2;

        if(this.activeOutlook === 'USA/SPC/CONVECTIVE/CATEGORICAL') {
          name = name.replace('Thunderstorms', '');
        }

        return {
          color: Object.values(rgb),
          name: name
        }
      });

      if(colortable.length === 0) {
        colortable.push({
          color: [0,0,0],
          name: 'No data'
        })
      }

      this.colortable = colortable
    },
    reset() {
      this.moreDropdownActive = false;
      window.map.popups.clear();

      const params = new URLSearchParams(window.location.hash.substr(1));
      params.delete('oid')
      params.delete('ost')

      // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
      window.location.hash = params.toString().replaceAll('%2F', '/')
    },
    clear() {
      this.moreDropdownActive = false;
      window.map.popups.clear();
      
      this.steps = [];
      this.colortable = [];
      window.map.outlooks.clear();
    },
    setUrlHash() {
      const params = new URLSearchParams(window.location.hash.substr(1));
      params.set('oid', this.activeOutlook)
      params.set('ost', this.activeStep)

      // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
      window.location.hash = params.toString().replaceAll('%2F', '/')
    },
    showHelp() {
      let h = null;
      let outlook = this.activeOutlook.slice(0);
      while(true) {
        h = help[outlook];

        if(h === undefined) {
          const outlookParts = outlook.split('/');
          outlookParts.pop();
          if(outlookParts.length === 0) break;

          outlook = outlookParts.join('/');
        }
        else {
          break;
        }
      }

      if(h === null) {
        return alert('Unable to locate help information');
      }

      window.map.outlooks.openOutlookHelpModal(h.title, h.text);
    },
    share() {
      modals.share();
    },
    close() {
      this.active = false;
      this.clear();
    },
    onMoreClick() {
      this.moreDropdownActive = !this.moreDropdownActive;
    },
    onCloseMore() {
      this.moreDropdownActive = false;
    }
  }
}
</script>

<style scoped>

</style>
