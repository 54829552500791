import { productGroups } from '../usa_radar_products.js'

export const Lookup = {};

function parsePal(content, step = 1) {
    function convertStringToColorArray(colorString) {
        const colorArray = [];
        const lines = colorString.trim().split('\n');
        
        lines.forEach(line => {
            const parts = line.split(/\s+/);  // Split by any whitespace

            // Check for color param
            if(parts[0].toLowerCase() !== 'color:') return;

            const index = parseFloat(parts[1]);
            const rgb = parts.slice(2, 5).map(Number);
            colorArray.push([index, rgb ]);
        });
        
        return colorArray;
    }

    const generateMap = (colorPoints, step = 1) => {
        const minValue = colorPoints[0][0];
        const maxValue = colorPoints[colorPoints.length - 1][0];
        const distance = parseInt(maxValue - minValue) / step;

        // console.log(minValue, maxValue, distance, step);

        const colors = [];

        function interpolateColor(color1, color2, factor) {
            return color1.map((color, i) => Math.round(color + (color2[i] - color) * factor));
        }

        for (let i = 0; i <= distance; i++) {
            const value = minValue + i * step;
            for (let j = 0; j < colorPoints.length - 1; j++) {
                if (colorPoints[j][0] <= value && value <= colorPoints[j + 1][0]) {
                    const t = (value - colorPoints[j][0]) / (colorPoints[j + 1][0] - colorPoints[j][0]);
                    const color = interpolateColor(colorPoints[j][1], colorPoints[j + 1][1], t);
                    colors.push([color[0], color[1], color[2]]);
                    break;
                }
            }
        }

        return {
            colors,
            min: minValue,
            max: maxValue
        }
    }

    return generateMap(convertStringToColorArray(content), step);
}

const ReflectivityPal = `
Product: BR
Units: dBZ
Step: 5
Scale: 1
Offset: 0

Color: -12 53 8 72
Color: -10 59 33 64
Color: -5 64 63 66
Color: 0 118 117 118
Color: 5 188 187 187
Color: 10 63 94 161
Color: 15 92 171 216
Color: 20 35 187 83
Color: 25 2 154 11
Color: 30 0 115 5
Color: 35 0 77 0
Color: 40 255 214 0
Color: 45 255 173 0
Color: 50 255 23 0
Color: 55 213 18 3 168 15 0
Color: 60 96 8 0 51 4 4
Color: 65 255 191 253
Color: 70 200 117 199 148 56 149
Color: 75 255 252 255
Color: 80 255 252 255
`;

const Reflectivity = parsePal(ReflectivityPal, 1);
productGroups.filter(g => g.id === 'REF').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = Reflectivity))

const VelocityPal = `
Color: ${-120*0.514444} 252 0 131
Color: ${-100*0.514444} 110 3 152
Color: ${-90*0.514444}  24 39 164
Color: ${-80*0.514444} 30 111 187
Color: ${-70*0.514444} 47 222 225
Color: ${-50*0.514444} 181 237 240
Color: ${-40*0.514444} 3 234 3
Color: ${-10*0.514444} 78 121 77
Color: ${0*0.514444} 137 117 123
Color: ${10*0.514444} 109 0 1
Color: ${40*0.514444} 249 51 77
Color: ${55*0.514444} 253 160 202
Color: ${60*0.514444} 253 228 159
Color: ${80*0.514444} 254 142 79
Color: ${120*0.514444} 110 14 8
`;

const Velocity = parsePal(VelocityPal, 1);

productGroups.filter(g => g.id === 'VEL').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = Velocity))

const DiffReflectivityPal = `
Color: -3.0 75 0 130      # Indigo (Hex: #4B0082) (Strong negative ZDR)
Color: -2.0 128 0 128     # Purple (Hex: #800080)
Color: -1.0 139 0 139     # Dark Magenta (Hex: #8B008B)
Color: 0.0 255 255 255    # White (Hex: #FFFFFF) (Neutral ZDR, near zero)
Color: 0.5 173 216 230    # Light Blue (Hex: #ADD8E6)
Color: 1.0 135 206 250    # Sky Blue (Hex: #87CEFA)
Color: 1.5 0 191 255      # Deep Sky Blue (Hex: #00BFFF)
Color: 2.0 0 128 0        # Green (Hex: #008000)
Color: 3.0 50 205 50      # Lime Green (Hex: #32CD32)
Color: 4.0 255 255 0      # Yellow (Hex: #FFFF00)
Color: 5.0 255 165 0      # Orange (Hex: #FFA500)
Color: 6.0 255 69 0       # Orange Red (Hex: #FF4500)
Color: 7.0 255 0 0        # Red (Hex: #FF0000) (Strong positive ZDR)
`;

const DiffReflectivity = parsePal(DiffReflectivityPal, 0.5);

productGroups.filter(g => g.id === 'ZDR').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = DiffReflectivity))

const CorrelationCoefficientPal = `
Color: 0.2 0 0 0
Color: 0.4 148 148 148
Color: 0.6 117 147 255
Color: 0.2 0 69 189
Color: 0.8 173 244 255
Color: 1 194 0 71
Color: 1.2 255 235 242
`;

const CorrelationCoefficient = parsePal(CorrelationCoefficientPal, 0.01);

productGroups.filter(g => g.id === 'CC').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = CorrelationCoefficient))

const SpectrumWidthPal = `
Color: 0 5 5 6
Color: 2 42 42 49
Color: 4 78 77 91
Color: 6 115 114 133
Color: 8 152 148 173
Color: 10 200 109 105
Color: 12 246 75 42
Color: 14 248 116 52
Color: 16 250 161 63
Color: 18 252 203 73
Color: 20 255 244 83
Color: 22 255 248 111
Color: 24 255 250 140
Color: 26 255 251 170
Color: 28 255 252 199
Color: 30 255 254 226
Color: 32 246 246 246
`;

const SpectrumWidth = parsePal(SpectrumWidthPal, 0.5);

productGroups.filter(g => g.id === 'NSW').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = SpectrumWidth))

const SpecificDifferentialPhasePal = `
Color: -2.0 75 0 130       # Indigo (Hex: #4B0082) (Strong negative KDP, rare or noise)
Color: -1.0 139 0 139      # Dark Magenta (Hex: #8B008B)
Color: -0.5 128 0 128      # Purple (Hex: #800080)
Color: 0.0 255 255 255     # White (Hex: #FFFFFF) (Neutral, no specific differential phase)
Color: 0.5 173 216 230     # Light Blue (Hex: #ADD8E6)
Color: 1.0 0 191 255       # Deep Sky Blue (Hex: #00BFFF)
Color: 2.0 0 128 0         # Green (Hex: #008000)
Color: 3.0 50 205 50       # Lime Green (Hex: #32CD32)
Color: 4.0 255 255 0       # Yellow (Hex: #FFFF00)
Color: 5.0 255 165 0       # Orange (Hex: #FFA500)
Color: 6.0 255 69 0        # Orange Red (Hex: #FF4500)
Color: 7.0 255 0 0         # Red (Hex: #FF0000) (High positive KDP, intense rainfall)
`;

const SpecificDifferentialPhase = parsePal(SpecificDifferentialPhasePal, 0.1);

productGroups.filter(g => g.id === 'KDP').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = SpecificDifferentialPhase))

const HydrometeorClassificationPal = `
Color: 10 156 156 156
Color: 20 118 118 118
Color: 30 243 179 178
Color: 40 117 250 243
Color: 50 63 141 247
Color: 60 115 247 154
Color: 70 84 184 54
Color: 80 208 207 112
Color: 90 199 135 134
Color: 100 234 51 36
Color: 110 147 37 30
Color: 120 255 254 84
Color: 130 0 0 0
Color: 140 212 45 246
Color: 150 109 18 121
`;

const HydrometeorClassification = parsePal(HydrometeorClassificationPal, 1);

productGroups.filter(g => g.id === 'HCA').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = HydrometeorClassification))

const StormRelativeVelocityPal = `
Color: -50.0 0 100 0 # Dark Green (Hex: #006400)
Color: -40.0 0 128 0 # Green (Hex: #008000)
Color: -30.0 0 192 0 # Lighter Green (Hex: #00C000)
Color: -20.0 128 224 128 # Light Green (Hex: #80E080)
Color: -10.0 192 255 192 # Very Light Green (Hex: #C0FFC0)
Color: 0.0 255 255 255 # White (Hex: #FFFFFF)
Color: 10.0 255 224 224 # Very Light Pink (Hex: #FFE0E0)
Color: 20.0 255 192 192 # Light Pink (Hex: #FFC0C0)
Color: 30.0 255 128 128 # Light Red (Hex: #FF8080)
Color: 40.0 255 0 0 # Red (Hex: #FF0000)
Color: 50.0 139 0 0 # Dark Red (Hex: #8B0000)
`;

const StormRelativeVelocity = parsePal(StormRelativeVelocityPal, 1);

productGroups.filter(g => g.id === 'SRV').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = StormRelativeVelocity))

const StormTotalPrecipAccumPal = `
Color: 0 0 0 0
Color: 0.1 100 100 100
Color: 0.25 60 60 60
Color: 0.5 0 200 255
Color: 0.75 0 150 255
Color: 1 0 100 255
Color: 1.5 0 0 255
Color: 2 0 255 0
Color: 2.5 0 200 0
Color: 3 0 150 0
Color: 4 255 255 0
Color: 5 255 200 0
Color: 6 255 150 0
Color: 8 255 100 0
Color: 10 255 0 0
Color: 12 200 0 0
Color: 15 150 0 0
Color: 20 100 0 0
`;
const StormTotalPrecipAccum = parsePal(StormTotalPrecipAccumPal, 0.1);

productGroups.filter(g => g.id === 'DTA').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = StormTotalPrecipAccum))

const OneHourPrecipAccumPal = `
Color: 0.0 255 255 255    # White (0 inches, no precipitation)
Color: 0.1 173 216 230    # Light Blue (Hex: #ADD8E6) (0.1 inches)
Color: 0.2 135 206 250    # Sky Blue (Hex: #87CEFA) (0.2 inches)
Color: 0.3 0 191 255      # Deep Sky Blue (Hex: #00BFFF) (0.3 inches)
Color: 0.5 0 128 0        # Green (Hex: #008000) (0.5 inches)
Color: 0.75 50 205 50     # Lime Green (Hex: #32CD32) (0.75 inches)
Color: 1.0 255 255 0      # Yellow (Hex: #FFFF00) (1.0 inch)
Color: 1.5 255 165 0      # Orange (Hex: #FFA500) (1.5 inches)
Color: 2.0 255 69 0       # Orange Red (Hex: #FF4500) (2.0 inches)
Color: 2.5 255 0 0        # Red (Hex: #FF0000) (2.5 inches)
Color: 3.0 139 0 0        # Dark Red (Hex: #8B0000) (3.0 inches)
Color: 4.0 128 0 128      # Purple (Hex: #800080) (4.0 inches)
Color: 5.0 75 0 130       # Indigo (Hex: #4B0082) (5.0 inches and above)
`;
const OneHourPrecipAccum = parsePal(OneHourPrecipAccumPal, 0.1);

productGroups.filter(g => g.id === 'OHA').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = OneHourPrecipAccum))

const EchoTopsPal = `
Color: 0.0 255 255 255    # White (0 kft, no significant echo)
Color: 5.0 173 216 230    # Light Blue (Hex: #ADD8E6) (5 kft)
Color: 10.0 135 206 250   # Sky Blue (Hex: #87CEFA) (10 kft)
Color: 15.0 0 191 255     # Deep Sky Blue (Hex: #00BFFF) (15 kft)
Color: 20.0 0 128 0       # Green (Hex: #008000) (20 kft)
Color: 25.0 50 205 50     # Lime Green (Hex: #32CD32) (25 kft)
Color: 30.0 255 255 0     # Yellow (Hex: #FFFF00) (30 kft)
Color: 35.0 255 165 0     # Orange (Hex: #FFA500) (35 kft)
Color: 40.0 255 69 0      # Orange Red (Hex: #FF4500) (40 kft)
Color: 45.0 255 0 0       # Red (Hex: #FF0000) (45 kft)
Color: 50.0 139 0 139     # Dark Magenta (Hex: #8B008B) (50 kft)
Color: 55.0 128 0 128     # Purple (Hex: #800080) (55 kft)
Color: 60.0 75 0 130      # Indigo (Hex: #4B0082) (60 kft)
Color: 65.0 148 0 211     # Dark Violet (Hex: #9400D3) (65 kft)
`

const EchoTops = parsePal(EchoTopsPal, 0.5);

productGroups.filter(g => g.id === 'EET').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = EchoTops))

const VILPal = `
Color: 0.0 255 255 255    # White (0 kg/m², no significant VIL)
Color: 5.0 173 216 230    # Light Blue (Hex: #ADD8E6) (5 kg/m²)
Color: 10.0 135 206 250   # Sky Blue (Hex: #87CEFA) (10 kg/m²)
Color: 15.0 0 191 255     # Deep Sky Blue (Hex: #00BFFF) (15 kg/m²)
Color: 20.0 0 128 0       # Green (Hex: #008000) (20 kg/m²)
Color: 25.0 50 205 50     # Lime Green (Hex: #32CD32) (25 kg/m²)
Color: 30.0 255 255 0     # Yellow (Hex: #FFFF00) (30 kg/m²)
Color: 35.0 255 165 0     # Orange (Hex: #FFA500) (35 kg/m²)
Color: 40.0 255 69 0      # Orange Red (Hex: #FF4500) (40 kg/m²)
Color: 45.0 255 0 0       # Red (Hex: #FF0000) (45 kg/m²)
Color: 50.0 139 0 0       # Dark Red (Hex: #8B0000) (50 kg/m²)
Color: 55.0 128 0 128     # Purple (Hex: #800080) (55 kg/m²)
Color: 60.0 75 0 130      # Indigo (Hex: #4B0082) (60 kg/m²)
Color: 65.0 148 0 211     # Dark Violet (Hex: #9400D3) (65 kg/m²)
`

const VIL = parsePal(VILPal, 0.5);

productGroups.filter(g => g.id === 'VIL').forEach(g => g.tilts.map(t => t.product).forEach(r => Lookup[r] = VIL))



