export default {
  'TO.W': {
    priority: 1,
    color: '#FF0000',
    name: 'Tornado Warning',
    icon: 'bi:tornado',
    help: `A Tornado Warning is issued when a tornado has been spotted by trained weather observers or detected by the WSR-88D radar. When this happens, residents in the affected area should take immediate shelter. Tornado Warnings can be issued even if a Tornado Watch is not already in place, and they typically last about 30 minutes.

Your local National Weather Service office (NWFO) issues Tornado Warnings, which detail where the tornado was detected and which towns are in its projected path. If the tornado could impact coastal or nearshore waters, the warning will be combined with a Special Marine Warning. In cases where the storm causing the tornado is also producing heavy rain, the warning may also be combined with a Flash Flood Warning. A warning that includes an ampersand (&) symbol at the bottom indicates it was based on a confirmed severe weather report.

Once the warning is issued, the NWFO will provide updates through Severe Weather Statements. These statements will offer current information on the tornado and announce when the warning is no longer in effect.`
  },
  'SV.W': {
    priority: 2,
    color: '#FFA500',
    name: 'Severe Thunderstorm Warning',
    icon: 'uil:thunderstorm',
    help: `A Severe Thunderstorm Warning is issued when the WSR-88D radar detects a severe thunderstorm, or when a spotter reports a storm producing hail of one inch or larger in diameter, and/or winds of 58 miles per hour or higher. People in the affected area should seek shelter immediately, as severe thunderstorms can produce tornadoes with little or no warning. Lightning frequency is not a factor in issuing this warning. Typically, these warnings last for about an hour and can be issued even without a preceding Severe Thunderstorm Watch.

Similar to a Tornado Warning, Severe Thunderstorm Warnings are issued by your local National Weather Service Forecast Office (NWFO). They provide details about the storm's location, the towns it may impact, and the primary threats associated with it. If the storm is expected to affect nearshore or coastal waters, the warning may be combined with a Special Marine Warning. Additionally, if torrential rains accompany the storm, the warning might also include a Flash Flood Warning. An ampersand (&) at the bottom of the warning indicates that it was based on a severe weather report.

Once issued, the NWFO will periodically update the public with Severe Weather Statements, providing the latest information on the storm and announcing when the warning is no longer in effect.`
  },
  'FF.W': {
    priority: 3,
    color: '#79ff26',
    name: 'Flash Flood Warning',
    icon: 'material-symbols:flood',
    help: `This is issued to notify the public, emergency management, and cooperating agencies when flash flooding is either currently happening, imminent, or very likely in the near future, based on data from Doppler radar, stream gauges, or reports from Skywarn spotters or local emergency officials.

    A flash flood occurs within six hours of heavy rainfall and poses significant risks to life and property, especially fin low-lying, flood-prone areas like urban zones with poor drainage, rivers, and streams. Other causes, such as ice jams or dam failures, can also trigger flash floods.

    These warnings are issued by the local Weather Forecast Office for specific counties (or their equivalents) and typically last between two and six hours, though they may extend longer during tropical cyclones or occasionally be shorter than two hours.`
  },
  'MA.W': {
    priority: 4,
    color: '#731e56',
    name: 'Special Marine Warning',
    icon: 'carbon:marine-warning',
    help: `A warning issued for potentially hazardous weather conditions, typically lasting up to 2 hours, that involve sustained marine thunderstorm winds or gusts of 34 knots or more, hail 3/4 inch in diameter or larger, or waterspouts.

    This warning applies to areas covered by a Coastal Waters Forecast, Nearshore Marine Forecast, or Great Lakes Open Lakes Forecast when existing marine warnings are insufficient. It is also used for short-term mesoscale events, such as strong cold fronts, gravity waves, or squall lines, that produce winds or gusts of 34 knots or greater and last less than 2 hours.`
  },
  'SP.S': {
    priority: 5,
    color: '#00FFFF',
    name: 'Special Weather Statement',
    icon: 'mdi:weather-cloudy-alert',
    help: `An official National Weather Service product that provides follow-up information on severe weather events—such as severe thunderstorms and tornadoes—that have occurred or are currently occurring.`
  },
  'TO.A': {
    priority: 6,
    color: '#FFFF00',
    name: 'Tornado Watch',
    icon: 'bi:tornado',
    help: `A Tornado Watch is issued by the National Weather Service when conditions are conducive to the formation of tornadoes in and near the designated area. The size of the watch area varies depending on the specific weather situation and is typically issued for 4 to 8 hours. Watches are generally released well ahead of severe weather to give people time to prepare. During this time, individuals should review tornado safety protocols and be ready to seek shelter if threatening weather develops.

The Storm Prediction Center (SPC) in Norman, Oklahoma, is responsible for issuing Tornado Watches. Before doing so, the SPC usually consults with the local National Weather Forecast Office (NWFO) to discuss current weather conditions. Once a preliminary Tornado Watch is issued by the SPC, the local NWFO may adjust the watch area, adding or removing counties as necessary, and then release it to the public. The NWFO keeps the public updated on conditions in the watch area and announces when the watch expires or is canceled, often through a Watch Redefining Statement.`
  },
  'SV.A': {
    priority: 7,
    color: '#DB7093',
    name: 'Severe Thunderstorm Watch',
    icon: 'uil:thunderstorm',
    help: `The National Weather Service issues a Severe Thunderstorm Watch when conditions are favorable for the formation of severe thunderstorms in or near the designated area. A severe thunderstorm is defined as one that produces hail at least one inch in diameter and/or winds of 58 miles per hour or greater. The size of the watch area can vary depending on the weather situation and is typically in effect for 4 to 8 hours. These watches are issued well before severe weather is expected, giving people time to review safety guidelines and be ready to move to a safe location if threatening weather approaches.

Severe Thunderstorm Watches are issued by the Storm Prediction Center (SPC) in Norman, Oklahoma. Before a watch is issued, the SPC typically consults with the local National Weather Service Forecast Office (NWFO) to assess the weather situation. After this, the SPC issues a preliminary watch, which the local NWFO can adjust by adding or removing counties or parishes before releasing it to the public through a Watch Redefining Statement. Throughout the watch period, the NWFO will keep the public updated on conditions in the watch area and notify them when the watch is canceled or expires.`
  },
  'FF.A': {
    priority: 8,
    color: '#2E8B57',
    name: 'Flash Flood Watch',
    icon: 'material-symbols:flood',
    help: `Issued to highlight current or developing hydrologic conditions that increase the likelihood of flash flooding in and around the watch area, though the event is not guaranteed or immediately expected.`
  }
}
