<script setup>
import { Icon } from '@iconify/vue'
import { useTimeAgo } from '@vueuse/core'
import { bbox, centroid } from '@turf/turf'
</script>

<template>
  <Transition name="slide-fade">
    <div v-show="active"
      class="absolute top-4 bottom-4 right-4 w-11/12 sm:w-80 max-w-md bg-white rounded-2xl shadow-2xl z-50 overflow-hidden flex flex-col mb-10 sm:mb-0">
      <div class="p-4 border-b border-gray-200">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xl font-semibold text-[#17274F]">Current Alerts ({{ warningsCount }})</h2>
          <button @click="onCloseClick" class="text-gray-500 hover:text-gray-700">
            <Icon icon="mdi:close" class="size-6" />
          </button>
        </div>
        <div class="flex flex-wrap gap-2 text-sm">
          <button v-for="filter in filters" :key="filter" @click="currentFilter = filter"
            class="px-3 py-1 rounded-full font-semibold transition-colors"
            :class="currentFilter === filter ? 'bg-[#F6B91A] text-[#17274F]' : 'bg-[#17274F] text-[#F6B91A]'">
            {{ filter }}
          </button>
        </div>
      </div>
      <div class="p-2 flex-grow overflow-y-auto">
        <!-- Warnings -->
        <div v-if="showWarnings">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] mb-2">Warnings ({{ warningsStore.filter('W').length }})</h3>
            <div v-for="product in codes.warnings" :key="product">
              <div v-if="warningsStore.filter('W', product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToConfig(product, 'W').icon" class="w-8 h-8 mr-2"
                    :style="{ color: codeToConfig(product, 'W').color }" />
                  <span class="font-semibold">{{ codeToConfig(product, 'W').name.replace(' Warning', '') }}
                    ({{ warningsStore.filter('W', product).length }})</span>
                  <a href="#" class="ml-auto" @click.prevent="showWarningHelp(`${product}.W`)"><Icon icon="carbon:help" class="w-6 h-6" /></a>
                </div>
                <div v-for="feature in warningsStore.filter('W', product)" :key="feature.id"
                  :class="{'border-2 border-red-500': feature.properties.emergency}"
                  class="bg-red-100 p-3 rounded-lg mb-2 cursor-pointer" @click="goToWarning(feature)">
                  <div class="flex items-center mb-2">
                    <Icon v-if="feature.properties.emergency" icon="mdi:alert" class="text-red-500 mr-2 size-6" />
                    <div class="font-bold text-red-700">{{ feature.properties.tags.HAZARD || codeToConfig(product, 'W').name }}</div>
                  </div>
                  <div v-if="feature.properties.emergency"
                    class="bg-red-200 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded inline-block mb-1">
                    Emergency!</div>
                  <div class="text-sm text-red-900 mt-1" :title="feature.properties.expires_at">Expires
                    {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-t border-gray-200 mb-4" />
        </div>

        <!-- Statements -->
        <div v-if="showStatements">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] mb-2">Statements ({{ warningsStore.filter('S').length }})
            </h3>
            <div v-for="product in codes.statements" :key="product">
              <div v-if="warningsStore.filter('S', product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToConfig(product, 'S').icon" class="w-8 h-8 mr-2"
                    :style="{ color: codeToConfig(product, 'S').color }" />
                  <span class="font-semibold">{{ codeToConfig(product, 'S').name.replace(' Statement', '') }}
                    ({{ warningsStore.filter('S', product).length }})</span>
                  <a href="#" class="ml-auto" @click.prevent="showWarningHelp(`${product}.S`)"><Icon icon="carbon:help" class="w-6 h-6" /></a>
                </div>
                <div v-for="feature in warningsStore.filter('S', product)" :key="feature.id"
                  class="bg-blue-50 p-3 rounded-lg mb-2 cursor-pointer" @click="goToWarning(feature)">
                  <div class="font-semibold text-[#17274F]">{{ feature.properties.tags.HAZARD || codeToConfig(product, 'S').name }}</div>
                  <div class="text-sm text-gray-600 mt-1" :title="feature.properties.expires_at">Expires
                    {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-t border-gray-200 mb-4" />
        </div>

        <!-- Watches -->
        <div v-if="showWatches">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] mb-2">Watches ({{ warningsStore.filter('A').length }})</h3>
            <div v-for="product in codes.warnings" :key="product">
              <div v-if="warningsStore.filter('A', product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToConfig(product, 'W').icon" class="w-8 h-8 mr-2"
                    :style="{ color: codeToConfig(product, 'A').color }" />
                  <span class="font-semibold">{{ codeToConfig(product, 'A').name.replace(' Watch', '') }}
                    ({{ warningsStore.filter('A', product).length }})</span>
                  <a href="#" class="ml-auto" @click.prevent="showWarningHelp(`${product}.A`)"><Icon icon="carbon:help" class="w-6 h-6" /></a>
                </div>
                <div v-for="feature in warningsStore.filter('A', product)" :key="feature.id"
                  :class="{'border-2 border-red-500': feature.properties.emergency}"
                  class="bg-orange-100 p-3 rounded-lg mb-2 cursor-pointer" @click="goToWarning(feature)">
                  <div class="flex items-center mb-2">
                    <Icon v-if="feature.properties.emergency" icon="mdi:alert" class="text-orange-500 mr-2 size-6" />
                    <div class="font-bold text-orange-700">{{ feature.properties.tags.HAZARD || codeToConfig(product, 'A').name }}</div>
                  </div>
                  <div v-if="feature.properties.emergency"
                    class="bg-red-200 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded inline-block mb-1">
                    Emergency!</div>
                  <div class="text-sm text-red-900 mt-1" :title="feature.properties.expires_at">Expires
                    {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-t border-gray-200 mb-4" />
        </div>

        <!-- Mesoscale Discussions -->
        <div v-if="showDiscussions">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] mb-2">Mesoscale Discussions
              ({{ mesoscaleDiscussionsStore.geojson.features.length }})</h3>
            <div v-for="product in codes.mesoscale_discussons" :key="product">
              <div v-if="mesoscaleDiscussionsStore.filter(product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToMDConfig(product).icon" class="w-8 h-8 mr-2"
                    :style="{ color: codeToMDConfig(product).color }" />
                  <span
                    class="font-semibold">{{ codeToMDConfig(product).name.replace('Mesoscale ', '').replace(' Discussion', '') }}
                    ({{ mesoscaleDiscussionsStore.filter(product).length }})</span>
                    <a href="#" class="ml-auto" @click.prevent="showMDHelp(product)"><Icon icon="carbon:help" class="w-6 h-6" /></a>
                </div>
                <div v-for="feature in mesoscaleDiscussionsStore.filter(product)" :key="feature.id"
                  class="bg-green-50 p-3 rounded-lg mb-2 cursor-pointer" @click="goToMesoscaleDiscussion(feature)">
                  <div class="font-semibold text-[#17274F]"><strong>#{{ feature.properties.number }}</strong> affects:
                    <strong>{{ feature.properties.tags.AREAS_AFFECTED.join(', ') }}</strong></div>
                  <div class="text-sm text-gray-600">Concerning:
                    <strong>{{ feature.properties.tags.CONCERNING.join(', ') }}</strong></div>
                  <div v-if="feature.properties.tags.WATCH_PROBABILITY" class="text-sm text-gray-600">Probability of Watch:
                    <strong>{{ feature.properties.tags.WATCH_PROBABILITY }}%</strong></div>
                  <div class="text-sm text-gray-600 mt-1" :title="feature.properties.expires_at">Expires
                    {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>


  <button class="absolute top-4 right-4 w-12 h-12 rounded-full transition-all duration-300 group
               bg-[#F6B91A] hover:bg-[#17274F]" @click="onOpenClick($event)" v-show="! active">
    <Icon icon="mingcute:alert-line" class="inline size-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-colors duration-300
                                          text-[#17274F] group-hover:text-[#F6B91A]" />
    <span
      class="absolute top-1 left-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-[#fff] transform -translate-x-1/2 -translate-y-1/2 bg-[#FF5722] rounded-full"
      v-text="warningsCount"></span>
  </button>


</template>

<script>
import { useWarningsStore } from '@/stores/warnings'
import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions'

import nwsWarningConfig from '@/data/nws_warning_config.js'
import nwsMDConfig from '@/data/nws_mesoscale_discussion_config.js'

export default {
  name: 'Sidebar',
  data() {
    return {
      active: false,
      codes: {
        // These are ordered according to their importance
        warnings: ['TO', 'SV', 'FF', 'MA'],
        watches: ['TO', 'SV', 'FF'],
        statements: ['SP'],
        mesoscale_discussons: ['MCD', 'MPD']
      },
      filters: ['All', 'Warnings', 'Statements', 'Watches', 'Discussions'],
      currentFilter: 'All',
    }
  },
  computed: {
    warningsStore: () => useWarningsStore(),
    mesoscaleDiscussionsStore: () => useMesoscaleDiscussionsStore(),
    warningsCount() {
      return this.warningsStore.geojson.features.length + this.mesoscaleDiscussionsStore.geojson.features.length
    },
    showWarnings() {
      return this.currentFilter === 'All' || this.currentFilter === 'Warnings';
    },
    showStatements() {
      return this.currentFilter === 'All' || this.currentFilter === 'Statements';
    },
    showWatches() {
      return this.currentFilter === 'All' || this.currentFilter === 'Watches';
    },
    showDiscussions() {
      return this.currentFilter === 'All' || this.currentFilter === 'Discussions';
    },
  },
  methods: {
    setFilter(filter) {
      this.currentFilter = filter;
    },
    onOpenClick(e) {
      this.active = true;
    },
    onCloseClick(e) {
      this.active = false;
    },
    goToWarning(feature) {
      if(feature.geometry === null) return;
      
      this.active = false;
      
      const center = centroid(feature.geometry)

      window.map.warnings.fitBounds(feature);

      // For some strange reason, a delay is required otherwise the map does not move properly
      // When people select a warning quickly
      setTimeout(() => {
        window.map.radar.turnOnClosestRadar(center)
      }, 100)
    },
    showWarningHelp(type) {
      if(nwsWarningConfig[type] === undefined) {
        return alert('Unable to locate help information');
      }

      window.map.warnings.openWarningHelpModal(type);
    },
    goToMesoscaleDiscussion(feature) {
      this.active = false
      const box = bbox(feature.geometry)
      const center = centroid(feature.geometry)

      // TODO
      // Improve this
      window.map.fitBounds([
        [box[0], box[1]],
        [box[2], box[3]]
      ], {
        padding: window.innerWidth < 640 ? 60 : 120,
        duration: 0
      })

      // For some strange reason, a delay is required otherwise the map does not move properly
      // When people select a warning quickly
      setTimeout(() => {
        window.map.radar.turnOnClosestRadar(center)
      }, 100)
    },
    showMDHelp(code) {
      if(nwsMDConfig[code] === undefined) {
        return alert('Unable to locate help information');
      }

      window.map.mesoscaleDiscussions.openMDHelpModal(code);
    },
    codeToConfig(product, significance) {
      if(nwsWarningConfig[`${product}.${significance}`] === undefined) {
        console.error(`NWS config missing for: ${product}.${significance}`);
      }

      return nwsWarningConfig[`${product}.${significance}`]
    },
    codeToMDConfig(code) {
      return nwsMDConfig[code]
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}

.open-sidebar {
  position: fixed;
  right: 0px;
  top: 10px;
  z-index: 4;
}

#sidebar {
  position: fixed;
  top: 10px;
  right: 10px;
  height: calc(100% - 40px);
  width: 350px;
  color: white;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.4px);
  -webkit-backdrop-filter: blur(12.4px);
  overflow: auto;
  z-index: 4;
  user-select: none;

  .close-sidebar {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}

.group {
  padding: 10px;
}

.product-group {
  margin-bottom: 10px;
}

.title {
  font-weight: 900;
  margin-bottom: 6px;
}

.product-title {
  font-weight: 700;
  font-size: 18px;
}

.alert {
  margin-bottom: 5px;

  .heading {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .expires {
    font-size: 12px;
  }
}

.divider {
  width: 80%;
  margin: 10px 10% 10px 10%;
}
</style>
