export const degreesToCardinal = (degrees) => {
	// Normalize degrees to be within 0-360
	degrees = (degrees % 360 + 360) % 360;

	const directions = [
		'North', 'North-Northeast', 'Northeast', 'East-Northeast', 
		'East', 'East-Southeast', 'Southeast', 'South-Southeast', 
		'South', 'South-Southwest', 'Southwest', 'West-Southwest', 
		'West', 'West-Northwest', 'Northwest', 'North-Northwest'
	];

	const index = Math.round(degrees / 22.5) % 16;
	
	return directions[index];
}

export const getVCPName = (vcpNumber) => {
    const vcpNames = {
    	// NEXRAD
        12: "Clear Air Mode",
        31: "Clear Air Mode",
        32: "Clear Air Mode",
        35: "Precipitation Mode",
        112: "Precipitation Mode",
        121: "Precipitation Mode",
        212: "Precipitation Mode",
        215: "Precipitation Mode",
        // TDWR
        90: "Clear Air Mode",
        80: "Precipitation Mode"

    };
    
    // Retrieve the VCP name or "Unknown VCP" if not found
	return vcpNames[vcpNumber] || null;
};