import { mande } from 'mande'

class Api {
  constructor() {
    this.api = mande('https://data1.weatherwise.app', { headers: { 'Content-Type': null } })
  }

  instance() {
    return this.api
  }
}

export default new Api()
