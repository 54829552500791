// TODO
// Use web workers? It doesn't seem to be needed...
// https://github.com/mattdesl/gifenc/blob/master/test/encode_web_workers.html
// Use vite to import workers: https://vitejs.dev/guide/features.html#import-with-query-suffixes

import { GIFEncoder, quantize, applyPalette } from 'gifenc'
import moment from 'moment'
import { useRecordingStore } from '../../stores/recording';
import html2canvas from 'html2canvas'

const MAX_GIF_LENGTH = 10 * 1000;


export default class GifRecorder {
  constructor(map) {
    this.map = map
    this.canvas = map.getCanvas();
    this.recording = false;
    this.stopRecordingTimeout = null;
    this.recordingStore = useRecordingStore()

    const targetPixels = 1280 * 720;

    const width = this.canvas.width;
    const height = this.canvas.height;

    const originalPixels = width * height;

    // Calculate the scaling factor
    const scale = Math.sqrt(targetPixels / originalPixels);

    // Calculate the new dimensions
    let newWidth = Math.floor(width * scale);
    newWidth*=0.9;
    newWidth = parseInt(newWidth);
    // Ensure the dimensions are not odd
    if(newWidth % 2 == 1) --newWidth;

    let newHeight = Math.floor(height * scale);
    newHeight*=0.9;
    newHeight = parseInt(newHeight);
    if(newHeight % 2 == 1) --newHeight;

    this.output = {
      width: newWidth,
      height: newHeight
    }

    // console.log(this.output)
  }


  async download(buf, filename, type) {
    const blob = buf instanceof Blob ? buf : new Blob([buf], { type });

    navigator._download.blob(blob, type);
  }

  async record() {
    const width = this.output.width;
    const height = this.output.height;
    this.recording = true;
    this.recordingStore.startScreenshot();

    this.stopRecordingTimeout = setTimeout(() => {
      this.stop()
    }, MAX_GIF_LENGTH)

    // Create temporary target canvas
    const c = document.createElement('canvas');
    c.width = width
    c.height = height
    const targetCtx = c.getContext('2d', {
      willReadFrequently: true
    });

    const fps = 25
    const delay = 1000 / fps;

    // Setup an encoder that we will write frames into
    const gif = GIFEncoder();

    const watermark = new Image();
    watermark.src = 'ww-watermark.png'; // Replace with your watermark image path
    await watermark.decode(); // Ensure the image is loaded before using it

    // Only worry about 1 map overlay for now
    // Which is the colortable
    const overlays = document.querySelectorAll('.map-overlay');
    let overlay = null;
    let overlayCanvas = null;

    if(overlays.length > 0) {
      overlay = overlays[0];
      overlayCanvas = await html2canvas(overlay);
    }

    console.time('encode');

    // We use for 'of' to loop with async await
    while(this.recording) {
      // Render to 2D context
      targetCtx.drawImage(this.canvas, 0, 0, this.canvas.width, this.canvas.height, 0, 0, width, height);

      // Add watermark to the top right corner
      const watermarkWidth = 150; // Adjust as needed
      const watermarkHeight = 75; // Adjust as needed
      const marginTop = 30;
      const marginRight = 10;
      targetCtx.drawImage(
        watermark,
        c.width - watermarkWidth - marginRight,
        marginTop,
        watermarkWidth,
        watermarkHeight
      );

      if(overlayCanvas !== null) {
        targetCtx.drawImage(overlayCanvas, overlay.offsetLeft, overlay.offsetTop, this.canvas.width, this.canvas.height, 0, 0, c.width, c.height);
      }

      // Get RGBA data from canvas
      const data = targetCtx.getImageData(0, 0, width, height).data;

      // Choose a pixel format: rgba4444, rgb444, rgb565
      const format = "rgb444";

      // If necessary, quantize your colors to a reduced palette
      const palette = quantize(data, 256, { format });

      // Apply palette to RGBA data to get an indexed bitmap
      const index = applyPalette(data, palette, format);

      // Write frame into GIF
      gif.writeFrame(index, width, height, { palette, delay });

      // Wait a tick so that we don't lock up browser
      await new Promise(resolve => setTimeout(resolve, 0));
    }

    // Finalize stream
    gif.finish();

    console.timeEnd('encode');

    // Get a direct typed array view into the buffer to avoid copying it
    const buffer = gif.bytesView();

    const dt = moment.utc().format("YYYY_MM_DD_HH_mm_ss");
    const filename = `WeatherWise_${dt}.gif`;

    this.download(buffer, filename, 'image/gif');
  }

  stop() {
    this.recording = false;
    this.recordingStore.stopScreenshot();
    this.recordingStore.stop()
    if(this.stopRecordingTimeout != null) {
      clearTimeout(this.stopRecordingTimeout)
      this.stopRecordingTimeout = null
    }
  }
}
















