import { centroid } from '@turf/turf'

import { useWarningsStore } from '@/stores/warnings'
import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions'

class Robot {
  constructor(map) {
    this.map = map;

    this.warningsStore = useWarningsStore()
    this.mesoscaleDiscussionsStore = useMesoscaleDiscussionsStore()
  }

  async focusRadarOnWarning(warningId, radarProduct) {
    // Load latest warnings
    await this.warningsStore.load();

    const feature = this.warningsStore.getFeatureById(warningId);

    if(feature === undefined) {
      throw new Error(`Warning ${warningId} does not exist`);
    }

    console.log(feature)

    // TODO
    // Change this
    this.map.warnings.setRealtimeUpdates(false);
    this.map.warnings.renderWarnings({
      features: [feature]
    });

    // TODO
    // Move this method somewhere else
    // As it has nothing to do with warnings...
    this.map.warnings.fitBounds(feature);

    // TODO
    // We should come up with something better to hide all the other layers?
    this.map.mesoscaleDiscussions.hide();

    const center = centroid(feature.geometry)
    await this.map.radar.turnOnClosestOnlineRadar(center, radarProduct);

    this.map.triggerRepaint();
  }

  async focusRadarOnMesoscaleDiscussion(mdId, radarProduct) {
    // Load latest discussions
    await this.mesoscaleDiscussionsStore.load();

    const feature = this.mesoscaleDiscussionsStore.getFeatureById(mdId);

    if(feature === undefined) {
      throw new Error(`Mesoscale Discussion ${mdId} does not exist`);
    }

    console.log(feature)

    // TODO
    // Change this
    // Hide all warnings
    this.map.warnings.setRealtimeUpdates(false);
    this.map.warnings.renderWarnings({
      features: []
    });

    this.map.mesoscaleDiscussions.fitBounds(feature);

    const center = centroid(feature.geometry)
    await this.map.radar.turnOnClosestOnlineRadar(center, radarProduct);

    this.map.triggerRepaint();
  }

  async warningScreenshot(warningId, radarProduct = 'REF') {
    await this.focusRadarOnWarning(warningId, radarProduct);

    // For some strange reason, a small delay is needed here...
    setTimeout(async () => {
      await window.screenshot.take();
    }, 100)
  }

  async warningGif(warningId, radarProduct = 'REF') {
    await this.focusRadarOnWarning(warningId, radarProduct);

    // For some strange reason, a small delay is needed here...
    setTimeout(async () => {
      await window.gif.record();
    }, 100)

    setTimeout(() => {
      window.gif.stop();
    }, 3000)
  }

  async mesoscaleDiscussionScreenshot(mdId, radarProduct = 'REF') {
    await this.focusRadarOnMesoscaleDiscussion(mdId, radarProduct);

    // For some strange reason, a small delay is needed here...
    setTimeout(async () => {
      await window.screenshot.take();
    }, 100)
  }
}

export default Robot
