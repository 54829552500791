<script setup>
import { ref } from 'vue'
import { useMouseInElement } from '@vueuse/core'

const target = ref(null)

const { elementX, elementWidth } = useMouseInElement(target)
</script>

<template>
  <Transition name="slide-fade">
    <div v-if="active">
      <div :class="{
        '!h-[20px]': colorInspectorOpen
      }" class="top-colortable h-[8px] w-full top-0 lef-0 fixed map-overlay" @click.prevent="onToggleColorInspector" @mousemove="onMapColorValue(elementWidth, elementX)" @touchmove="onMapColorValue(elementWidth, elementX)" ref="target" :style="cssLinearGradient">
        <div v-show="screenshot" class="text-sm h-3/6 flex justify-between items-center">
          <div class="px-3 text-center select-none">
            <div class="bg-white/75 h-full text-black font-bold px-2 pt-1 rounded-md text-xs">
              {{ screenshotColorMapValues[0].value }} {{ colorMapValueUnit }}
            </div>
          </div>
          <div class="px-3 text-center select-none">
            <div class="bg-white/75 h-full text-black font-bold px-2 pt-1 rounded-md text-xs">
              {{ screenshotColorMapValues[1].value }} {{ colorMapValueUnit }}
            </div>
          </div>
          <div class="px-3 text-center select-none">
            <div class="bg-white/75 h-full text-black font-bold px-2 pt-1 rounded-md text-xs">
              {{ screenshotColorMapValues[2].value }} {{ colorMapValueUnit }}
            </div>
          </div>
        </div>
      </div>
      <div class="fixed top-5 w-full flex justify-center items-center z-30" v-show="colorInspectorOpen && ! screenshot" :style="`background-color: ${colorMapColor}`">
        <div class="bg-white/75 px-3 rounded-md text-center font-bold select-none">
          {{ colorMapValue.toFixed(1) }} {{ colorMapValueUnit }}
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { useRadarTowersStore } from '@/stores/radar_towers'
import { useRecordingStore } from '@/stores/recording'

export default {
  name: 'Colortable',
  data() {
    return {
      colorMapValue: 0,
      colorMapColor: '#fff',
      colorInspectorOpen: false,
      screenshot: false
    }
  },
  computed: {
    radarTowersStore: () => useRadarTowersStore(),
    recordingStore: () => useRecordingStore(),
    active() {
      return this.radarTowersStore.anyActive && this.radarTowersStore.activeColorMap !== null
    },
    cssLinearGradient() {
      if (! this.radarTowersStore.activeColorTable) {
        return;
      }

    	const len = this.radarTowersStore.activeColorTable.length || 0;
    	const colorsAsRgb = this.radarTowersStore.activeColorTable.map((c, i) => {
    		return `rgb(${c[0]},${c[1]},${c[2]}) ${(i/len)*100}%`
    	});
    	return `background: linear-gradient(90deg, ${colorsAsRgb.join(', ')});`;
    },
    colorMapValues() {
      if (!this.radarTowersStore.activeColorMap) {
        return []
      }

      const min = this.radarTowersStore.activeColorMap.min
      const max = this.radarTowersStore.activeColorMap.max
      const total = Math.abs(min) + max
      const step = total / (this.radarTowersStore.activeColorTable.length - 1)

      const length = Math.floor((max - min) / step) + 1;
      return Array.from({ length }, (_, i) => +(min + i * step).toFixed(10));
    },
    colorMapValueUnit() {
        if (! this.radarTowersStore.activeProductGroup?.unit) {
          return '';
        }

        return this.radarTowersStore.activeProductGroup.unit
    },
    screenshotColorMapValues() {
      const cm = this.radarTowersStore.activeColorMap

      if (! cm) {
        return [{value: 0, color: 'rgb(255, 255, 255)'}, {value: 0, color: 'rgb(255, 255, 255)'}, {value: 0, color: 'rgb(255, 255, 255)'}]
      }

      const min = cm.min
      const max = cm.max
      const c = cm.colors

      return [
        {
          value: parseFloat(min).toFixed(1),
          color: `rgb(${c[0][0]},${c[0][1]},${c[0][2]})`
        },
        {
          value: parseInt((min + max) / 2).toFixed(1),
          color: `rgb(${c[parseInt(c.length/2-1)][0]},${c[parseInt(c.length/2-1)][1]},${c[parseInt(c.length/2-1)][2]})`
        },
        {
          value: parseFloat(max).toFixed(1),
          color: `rgb(${c[c.length-1][0]},${c[c.length-1][1]},${c[c.length-1][2]})`
        }
      ]
    }
  },
  watch: {
    'active': {
      handler(newVal) {
        this.onCloseColorInspector()
      }
    },
    'recordingStore.screenshot': {
      handler(newVal) {
        if (newVal) {
          this.screenshot = true
          this.onOpenColorInspector()
          
        } else {
          this.screenshot = false
          this.onCloseColorInspector()
          
        }
      }
    }
  },
  methods: {
    onMapColorValue(elementX, elementWidth) {
      if (! this.radarTowersStore.activeColorTable) {
        return null
      }

      const colorTableLength = this.radarTowersStore.activeColorTable.length
      const colorTableUnitPercentage = 100 / colorTableLength

      const tableRelDistance =  parseInt((((elementWidth / elementX) * 100) / colorTableUnitPercentage))
      const colorValues = this.radarTowersStore.activeColorTable[tableRelDistance];

      if (! this.colorMapValues[tableRelDistance]) {
        return
      }

      this.colorMapValue = this.colorMapValues[tableRelDistance]
      this.colorMapColor = `rgb(${colorValues[0]}, ${colorValues[1]}, ${colorValues[2]})`
    },
    onToggleColorInspector() {
      this.colorInspectorOpen = ! this.colorInspectorOpen

      if (this.colorInspectorOpen) {
        this.onOpenColorInspector()
      } else {
        this.onCloseColorInspector()
      }
    },
    onOpenColorInspector() {
      this.colorInspectorOpen = true
      if (! this.screenshot && this.active) {
        document.body.classList.add(['body-colorbar-padding'])
      }
    },
    onCloseColorInspector() {
      this.colorInspectorOpen = false
      document.body.classList.remove('body-colorbar-padding')
      this.colorMapValue = 0
      this.colorMapColor = '#fff'
    }
  }
}
</script>

<style>
.body-colorbar-padding {
  padding-top: 40px;
}
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}

.top-colortable {
	z-index: 4
}
</style>
