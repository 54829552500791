import api from '../Api'

export default class BaseRadar {
  constructor(map) {
    this.map = map
  }

  async loadLatestFile(id, product) {
    // const list = await this.fetchLatestList(id, product)

    // // console.log(list)

    // if (list.length == 0) {
    //   // TODO
    //   return
    // }

    // const latestScanFilename = list[list.length - 1]

    const latestScanFilename = 'latest.json';

    return this.fetchJson(id, product, latestScanFilename)
  }

  async fetchLatestList(id, product) {
    const list = await (
      await api.instance().get(`/radar/${id}/${product}/dir.list`, { responseAs: 'response' })
    ).text()

    return list.split('\n').filter((l) => l.length > 0)
  }

  async fetchJson(id, product, filename) {
    return api.instance().get(`/radar/${id}/${product}/${filename}`)
  }
}
