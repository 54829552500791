import { distance } from '@turf/turf'
import { useHead } from '@unhead/vue'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'

import { PAGE_TITLE } from '@/brand'

import { stopPropagation } from '@/tools/mapbox-map'

import radarTowers from '@/data/radar_towers.geojson'
import { Lookup as ColormapLookup } from '@/data/Colortables/Radar.js'

import BaseRadar from './Radar/BaseRadar'
import StormTracks from './Radar/StormTracks'
import Lightning from './Radar/Lightning'
import { RadarRenderer } from "./Radar/Renderer/";

import SimpleModal from './Modals/Templates/Simple.vue'
import RadarProductHelpModal from './Radar/RadarProductHelp.vue'

import socket from '@/logic/Socket'

import { useRadarTowersStore, DEFAULT_RADAR_REF_PRODUCT_CODE, DEFAULT_RADAR_VEL_PRODUCT_CODE } from '@/stores/radar_towers'

export default class Radar extends BaseRadar {
  constructor(map) {
    super(map)

    this.radarTowersStore = useRadarTowersStore()

    this.radarRendererLayerId = 'radar-renderer';

    this.stormTracks = new StormTracks(map);
    this.lightning = new Lightning(map);
    this.renderer = new RadarRenderer(map, this.radarRendererLayerId, "land-structure-polygon");
    this.renderer.setOpacity(1);

    // TODO
    // Get value picker working
    // this.map.on('click', (e) => {
    //   if(this.radarTowersStore.activeTower) {
    //      const gate = this.renderer.getValueAt(e.lngLat);

    //      console.log(gate);
    //   }
    // });

    this.activeSocketRooms = [];

    // TODO
    // Move this
    radarTowers.features = radarTowers.features.map((f) => {
      const secondaryRadar = f.properties.secondary;

      f.properties.active = this.radarTowersStore.activeTowerId === f.properties.id;

      f.properties['icon-image'] = this.towerIcon(f)
      f.properties['icon-size'] = secondaryRadar ? 0.085 : 0.1
      f.properties['symbol-sort-key'] = secondaryRadar ? 2 : 1
      return f
    })

    this.towers = radarTowers;

    this.sourceId = 'radar-towers-source'
    this.layerId = 'radar-towers-layer'

    const iconsToLoad = [
      ['radar-tower-primary', '/radar/tower/primary.png'],
      ['radar-tower-secondary', '/radar/tower/secondary.png'],
      ['radar-tower-active', '/radar/tower/active.png'],
    ];

    const iconsToLoadPromises = iconsToLoad.map(icon => {
      return new Promise((resolve, reject) => {
         this.map.loadImage(
            icon[1],
            (error, image) => {
              if(error) return reject(error);

              // console.log(icon, image)

              this.map.addImage(icon[0], image);

              resolve();
            })
      });
    });

    // Load all the icons...
    Promise.allSettled(iconsToLoadPromises);

    // Render radar markers
    map.addSource(this.sourceId, {
      type: 'geojson',
      data: radarTowers
    })

    map.addLayer({
      id: this.layerId,
      type: 'symbol',
      source: this.sourceId,
      layout: {
        'icon-image': ['get', 'icon-image'],
        'icon-size': ['get', 'icon-size'],
        'icon-padding': 1,
        'symbol-sort-key': ['get', 'symbol-sort-key'],
        'icon-pitch-alignment': 'map'
      }
    })

    map.on('click', this.layerId, stopPropagation(async (e) => {
      console.log('click',e)

      const feature = radarTowers.features.find((f) => {
        return f.properties.id === e.features[0].properties.id
      })

      if (feature === undefined) return

      console.log(feature)

      if (feature.properties.active) {
        this.removeRadar(feature)

        feature.properties.active = false
        feature.properties['icon-image'] = this.towerIcon(feature)
        this.map.getSource(this.sourceId).setData(radarTowers)
      } else {
        // Close all other radar towers
        this.closeAllRadarTowers()

        this.turnOnRadar(feature)
      }
    }))

    const activeTower = radarTowers.features.find(f => f.properties.active);
    if(activeTower !== undefined) {
      this.turnOnRadar(activeTower)
    }

    this.hide()
  }

  closeAllRadarTowers() {
    this.towers.features.forEach((f) => {
      if (! f.properties.active) return

      this.removeRadar(f)
      f.properties.active = false
      f.properties['icon-image'] = this.towerIcon(f)
    });

    this.map.getSource(this.sourceId).setData(radarTowers)
  }

  async turnOnRadar(feature, product = null) {
    useHead({
      title: `${feature.properties.code} - ${PAGE_TITLE}`
    })

    feature.properties.active = true
    feature.properties['icon-image'] = this.towerIcon(feature)
    this.map.getSource(this.sourceId).setData(this.towers)

    this.radarTowersStore.setActiveTower(feature)
    this.radarTowersStore.setAvailableProducts(feature.properties.products)
    this.radarTowersStore.clearScanDatetime()
    this.radarTowersStore.clearScanVcp()

    if(product === null) {
      product = this.radarTowersStore.activeProductCode;
    }
    else {
      this.radarTowersStore.activeProductCode = product;
    }

    const room = `radar:${feature.properties.id}:${product}`
    this.activeSocketRooms.push(room)
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Radar update', room, data)

      // TODO
      // Load the url provided in the data
      this.loadLatestScan(feature, product)
    })

    const promises = [];

    promises.push(this.loadLatestScan(feature, product))

    // Check that storm tracks are supported
    if(feature.properties.products.includes('NST')) {
      const product = 'NST'
      const room = `radar:${feature.properties.id}:${product}`
      this.activeSocketRooms.push(room)
      socket.roomJoin(room)
      socket.on(room, async (data) => {
        console.log('Radar update', room, data)

        // TODO
        // Load the url provided in the data
        this.loadLatestStormTrack(feature)
      })
      promises.push(this.loadLatestStormTrack(feature))
    }

    promises.push(this.lightning.load(feature.properties.id));

    await Promise.allSettled(promises);
  }

  turnOnRadarViaId(id) {
    const feature = this.towers.features.find(f => f.properties.id === id)

    if(feature === undefined) return false

    this.closeAllRadarTowers()

    this.turnOnRadar(feature)

    return feature
  }

  changeRadarProduct(id, productCode) {
    const feature = this.towers.features.find(f => f.properties.id === id)

    if(feature === undefined) return false

    this.stopListeningToRooms()

    this.radarTowersStore.activeProductCode = productCode;

    this.turnOnRadar(feature)
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  removeRadar(feature) {
    useHead({
      title: PAGE_TITLE
    })

    this.radarTowersStore.clear()

    const towerId = feature.properties.id

    this.stopListeningToRooms();

    this.renderer.clear();

    if(feature.properties.products.includes('NST')) {
      this.stormTracks.clear(towerId)
    }

    this.lightning.clear(towerId);
  }

  getClosestTowers(position) {
    const majorTowers = this.towers.features.filter(f => !f.properties.secondary).map(f => {
      return {
        feature: f,
        distance: distance(f.geometry.coordinates, position, { units: "kilometers" })
      }
    })

    majorTowers.sort((a, b) => {
      return a.distance - b.distance;
    })

    return majorTowers;
  }

  turnOnClosestRadar(position) {
    const majorTowers = this.getClosestTowers(position);

    if(majorTowers.length == 0) return;

    this.closeAllRadarTowers()

    this.turnOnRadar(majorTowers[0].feature)

    // console.log(majorTowers)
  }

  async turnOnClosestOnlineRadar(position, product = 'REF') {
    // Now locate the nearest ONLINE radar (within 300 kilometers)
    // And turn on the lowest elevation ref product
    const closestTowers = this.map.radar.getClosestTowers(position).filter(f => f.distance < 300);

    if(closestTowers.length === 0) {
      let message = 'Could not locate a nearby tower'
      throw new Error(message)
    }

    const defaultProductCodes = (() => {
      if(product === 'REF') return DEFAULT_RADAR_REF_PRODUCT_CODE;
      else if(product === 'VEL') return DEFAULT_RADAR_VEL_PRODUCT_CODE;

      return [];
    })();

    // Loop through each close tower until we find a ref. scan
    // That is not too old (max 10 mins.)
    for (const t of closestTowers) {
      const tower = t.feature;
      const towerId = tower.properties.id;
      const product = (() => {
        for(const p of defaultProductCodes) {
          if(tower.properties.products.includes(p)) return p;
        }

        return null;
      })();

      if(product === null) continue;

      const scan = await this.loadLatestFile(towerId, product);

      const age = moment.utc().diff(moment.utc(scan.datetime), 'seconds');

      // Check if scan is too old
      if(age < 60 * 10) {
        this.closeAllRadarTowers();
        await this.turnOnRadar(tower, product);
        break;
      }
    }
  }

  async loadLatestScan(feature, product) {
    const towerId = feature.properties.id

    const scan = await this.loadLatestFile(towerId, product);

    if(scan === undefined || scan === null || typeof scan !== 'object') {
      return console.log(`Bad radar scan: ${towerId} ${product}`);
    }

    if(ColormapLookup[product] === undefined) {
      return console.error(`Missing colormap for product: ${product}`);
    }

    const colormap = ColormapLookup[product];

    // console.log({colormap, product})

    this.renderer.reset();
    this.renderer.clear();
    this.renderer.setColormap(colormap.colors);
    this.renderer.setMinMax(colormap.min, colormap.max);
    this.renderer.draw(scan);

    this.radarTowersStore.setColorMap(colormap)
    this.radarTowersStore.setScanDatetime(scan.datetime)

    const vcp = scan?.metadata?.vcp ?? null;
    this.radarTowersStore.setScanVcp(vcp);
  }

  async loadLatestStormTrack(feature) {
    const towerId = feature.properties.id

    const tracks = await this.loadLatestFile(towerId, 'NST')
    // console.log(tracks)

    this.stormTracks.draw(towerId, tracks)
  }

  towerIcon(f) {
    if (f.properties.active) return 'radar-tower-active'

    const secondaryRadar = f.properties.secondary;

    return secondaryRadar ? 'radar-tower-secondary' : 'radar-tower-primary'
  }

  openRadarProductHelpModal(productGroup) {
    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: productGroup.name
      },
      slots: {
        default: useModalSlot({
          component: RadarProductHelpModal,
          attrs: {
            text: productGroup.help,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  show() {
    for(const layerId of [this.layerId, this.radarRendererLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }

    this.lightning.show();
    this.stormTracks.show();
  }

  hide() {
    for(const layerId of [this.layerId, this.radarRendererLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }

    this.lightning.hide();
    this.stormTracks.hide();
  }
}
