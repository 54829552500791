import { defineStore } from 'pinia'
import moment from 'moment'

export const useRecordingStore = defineStore('recording', {
  state: () => ({
    recording: false,
    started_at: null,
    ended_at: null,
    screenshot: false
  }),

  actions: {
    startScreenshot() {
      this.screenshot = true
    },

    stopScreenshot() {
      this.screenshot = false
    },

    start() {
      this.recording = true;
      this.started_at = moment.utc();
    },

    stop() {
      this.recording = false;
      this.ended_at = moment.utc();
    }
  }
})
