<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { VueFinalModal } from 'vue-final-modal'

defineProps<{
  title?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void
}>()
</script>

<template>
  <VueFinalModal
      content-transition="vfm-fade"
      overlay-transition="vfm-fade"
      content-class="absolute inset-0"
      @update:model-value="(val) => emit('update:modelValue', val)"
    >
      <div
        class="flex justify-center items-center h-full"
        @click.self="() => emit('update:modelValue', false)"
      >
        <div class="flex flex-col max-w-3xl my-12 mx-auto p-4 bg-white rounded-lg space-y-2">
          <div class="flex items-center">
            <h1 v-if="title" class="text-2xl sm:text-4xl">
              {{ title }}
            </h1>
            <button class="ml-auto" @click="emit('update:modelValue', false)">
              <Icon icon="clarity:window-close-line" class="size-8 sm:size-10" />
            </button>
          </div>

          <slot />
        </div>
    </div>
  </VueFinalModal>
</template>
