import { toRaw } from 'vue'
import mapboxgl from 'mapbox-gl'
import { colord } from 'colord'
import { centroid, bbox, point, bearing, destination } from '@turf/turf'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'

import { stopPropagation } from '../tools/mapbox-map'

import radarTowers from '../data/radar_towers.geojson'
import nwsWarningConfig from '../data/nws_warning_config.js'

import { useWarningsStore } from '../stores/warnings'

import socket from '../logic/Socket'
import api from '../logic/Api'
import StormTracks from './Radar/StormTracks'
import SimpleModal from './Modals/Templates/Simple.vue'
import CenteredModal from './Modals/Templates/Centered.vue'
import WarningModal from './Warnings/Modal.vue'
import WarningNotFoundModal from './Warnings/NotFound.vue'
import WarningHelpModal from './Warnings/WarningHelp.vue'
import WarningHelpModalTitle from './Warnings/WarningHelpTitle.vue'

export default class Warnings {
  constructor(map) {
    this.map = map

    this.warningsStore = useWarningsStore()

    // Initialise the store
    this.warningsStore.init()

    this.realtimeUpdatesEnabled = true;

    this.normalSourceId = 'warnings-source'
    this.emergencySourceId = 'warnings-emergency-source'
    
    this.lineLayerId = 'warnings-line-layer'
    this.emergencyLineLayerId = 'warnings-emergency-line-layer'
    this.emergencyBackgroundLineLayerId = 'warnings-emergency-background-line-layer'
    
    this.fillLayerId = 'warnings-fill-layer'
    this.emergencyFillLayerId = 'warnings-emergency-fill-layer'

    this.addLayer()

    const warningOnClick = stopPropagation((e) => {
      if(e.features.length == 0) return;
      console.log(e.features)

      // Pull full feature from the store
      let feature = this.warningsStore.geojson.features.find(f => f.properties.id === e.features[0].properties.id);
      if(feature === undefined) return;
      feature = toRaw(feature)

      console.log(feature)

      this.openWarningModal(feature);
    });

    map.on('click', this.fillLayerId, warningOnClick)
    map.on('click', this.emergencyFillLayerId, warningOnClick)

    // Subscribe to updates
    this.warningsStore.$subscribe((mutation, state) => {
      const geojson = toRaw(state.geojson)

      this.renderWarnings(geojson)
    })

    // Render warnings already in the store (cached offline)
    this.renderWarnings(toRaw(this.warningsStore.geojson));

    // Request latest warnings
    (async () => {
      await this.warningsStore.load()
      
      // TODO
      // Refactor code handling params stored in the URL
      
      // Open warning from the url
      // After we've loaded the latest warnings
      const params = new URLSearchParams(window.location.hash.substr(1));
      if(params.has('wcid')) {
        const cid = params.get('wcid');

        const feature = this.warningsStore.geojson.features.find(f => f.properties.common_id === cid);

        if(feature !== undefined) {
          this.fitBounds(feature);

          setTimeout(() => {
            const center = centroid(feature.geometry)
            this.map.radar.turnOnClosestOnlineRadar(center);
          }, 100);

          this.openWarningModal(feature);

          return;
        }
      }

      if(params.has('wid')) {
        const id = params.get('wid');

        const feature = this.warningsStore.geojson.features.find(f => f.properties.id === id);

        if(feature !== undefined) {
          if(params.has('wr') && params.get('wr') == 1) {
            this.fitBounds(feature);
            
            setTimeout(() => {
              const center = centroid(feature.geometry);
              // Don't need to add await here
              this.map.radar.turnOnClosestOnlineRadar(center);

              const params = new URLSearchParams(window.location.hash.substr(1));
              params.delete('wr')

              // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
              window.location.hash = params.toString().replaceAll('%2F', '/')
            }, 500); 
          }

          return this.openWarningModal(feature);
        }

        // Warning is no longer active...

        // Try and load from the archive
        await this.fetchWarningFromArchive(id)
      }
    })();
    
    // Subscribe to warnings events
    socket.roomJoin('warnings')

    socket.on('warnings', async (data) => {
      if(! this.realtimeUpdatesEnabled) return console.log(`Incoming warning, but ignoring due to realtime updates disabled...`);

      console.log('Warnings update', data)

      if(data.properties.action === 'CAN') {
        this.warningsStore.delete(data.properties.id);
      }
      else {
        // A new warning is a simple case, just append to our local list of warnings
        await this.warningsStore.push(data)
      }
    })
  }

  addLayer() {
    this.map.addSource(this.normalSourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addSource(this.emergencySourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.map.addLayer({
      id: this.emergencyLineLayerId,
      type: 'line',
      source: this.emergencySourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width'],
        'line-dasharray': [0, 4, 3]
      }
    })

    this.map.addLayer({
      id: this.emergencyBackgroundLineLayerId,
      type: 'line',
      source: this.emergencySourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-background-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, this.emergencyLineLayerId)

    this.map.addLayer({
      id: this.emergencyFillLayerId,
      type: 'fill',
      source: this.emergencySourceId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
      }
    }, this.emergencyLineLayerId)

    this.map.addLayer({
      id: this.lineLayerId,
      type: 'line',
      source: this.normalSourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, this.emergencyFillLayerId)

    this.map.addLayer({
      id: this.fillLayerId,
      type: 'fill',
      source: this.normalSourceId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
      }
    }, this.lineLayerId)

    this.motionPointsSourceId = 'warnings-motion-points-source'
    this.motionPointsLayerId = 'warnings-motion-points-layer'

    this.motionPathSourceId = 'warnings-motion-path-source'
    this.motionPathLayerId = 'warnings-motion-path-layer'

    this.motionArrowHeadSourceId = 'warnings-motion-arrow-head-source'
    this.motionArrowHeadLayerId = 'warnings-motion-arrow-head-layer'

    // Add source/layer for motion points
    map.addSource(this.motionPointsSourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    map.addLayer({
      id: this.motionPointsLayerId,
      type: 'circle',
      source: this.motionPointsSourceId,
      paint: {
        'circle-radius': 3,
        'circle-stroke-width': 1,
        'circle-color': ['get', 'circle-color'],
        'circle-stroke-color': ['get', 'circle-stroke-color']
      },
      minzoom: 6
    })

    // Add source/layer for motion path
    map.addSource(this.motionPathSourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    map.addLayer(
      {
        id: this.motionPathLayerId,
        type: 'line',
        source: this.motionPathSourceId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': ['get', 'line-color'],
          'line-width': 2
        },
        minzoom: 6
      },
      this.motionPointsLayerId
    )

    // Add source/layer for motion arrow head
    map.addSource(this.motionArrowHeadSourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    map.addLayer(
      {
        id: this.motionArrowHeadLayerId,
        type: 'fill',
        source: this.motionArrowHeadSourceId,
        paint: {
          'fill-color': ['get', 'fill-color']
        },
        minzoom: 6
      },
      this.motionPointsLayerId
    )

    const dashArraySequence = [
        [0, 4, 3],
        [0.5, 4, 2.5],
        [1, 4, 2],
        [1.5, 4, 1.5],
        [2, 4, 1],
        [2.5, 4, 0.5],
        [3, 4, 0],
        [0, 0.5, 3, 3.5],
        [0, 1, 3, 3],
        [0, 1.5, 3, 2.5],
        [0, 2, 3, 2],
        [0, 2.5, 3, 1.5],
        [0, 3, 3, 1],
        [0, 3.5, 3, 0.5]
    ];

    const me = this;
    let step = 0;
    function animateDashArray(timestamp) {
        // Update line-dasharray using the next value in dashArraySequence. The
        // divisor in the expression `timestamp / 50` controls the animation speed.
        const newStep = parseInt(
            (timestamp / 50) % dashArraySequence.length
        );

        if (newStep !== step) {
            map.setPaintProperty(
                me.emergencyLineLayerId,
                'line-dasharray',
                dashArraySequence[step]
            );
            step = newStep;
        }

        // Request the next frame of the animation.
        requestAnimationFrame(animateDashArray);
    }

    // start the animation
    animateDashArray(0);
  }

  openWarningHelpModal(type) {
    const config = nwsWarningConfig[type]

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: config.name
      },
      slots: {
        title: useModalSlot({
          component: WarningHelpModalTitle,
          attrs: {
            config,
            onClose() {
              modal.close()
            },
          }
        }),
        default: useModalSlot({
          component: WarningHelpModal,
          attrs: {
            config,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  openWarningModal(feature) {
    const config = nwsWarningConfig[`${feature.properties.product}.${feature.properties.significance}`]

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: config.name,
        onOpened() {
          const params = new URLSearchParams(window.location.hash.substr(1));
          params.set('wid', feature.properties.id)

          // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
          window.location.hash = params.toString().replaceAll('%2F', '/')
        },
        onClosed() {
          const params = new URLSearchParams(window.location.hash.substr(1));
          params.delete('wid')

          // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
          window.location.hash = params.toString().replaceAll('%2F', '/')
        },
      },
      slots: {
        default: useModalSlot({
          component: WarningModal,
          attrs: {
            feature: feature,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  openNotFoundModal() {
    return useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'Warning Not Found',
      },
      slots: {
        default: useModalSlot({
          component: WarningNotFoundModal
        })
      },
    })
  }

  applyPropertiesToFeature(f) {
    const config = nwsWarningConfig[`${f.properties.product}.${f.properties.significance}`]

    f.properties['line-color'] = f.properties.emergency ? '#FFFFFF' : config.color
    f.properties['line-background-color'] = config.color
    f.properties['line-opacity'] = 1
    f.properties['line-width'] = f.properties.emergency ? 4 : 2
    f.properties['line-sort-key'] = 1000 - config.priority

    f.properties['fill-color'] = config.color
    f.properties['fill-opacity'] = 0.1;
    f.properties['fill-sort-key'] = 1000 - config.priority
    return f
  }

  renderWarnings(geojson) {
    const features = geojson.features.filter((f) => {
      const config = nwsWarningConfig[`${f.properties.product}.${f.properties.significance}`]

      if (config === undefined) {
        console.log(`NWS config missing for: ${f.properties.product}`)
      }

      return config !== undefined;
    }).map((f) => {
      return this.applyPropertiesToFeature(f);
    })

    const normalFeatures = features.filter(f => !f.properties.emergency);
    const emergencyFeature = features.filter(f => f.properties.emergency);

    this.map.getSource(this.normalSourceId).setData({
      type: 'FeatureCollection',
      features: normalFeatures
    })

    this.map.getSource(this.emergencySourceId).setData({
      type: 'FeatureCollection',
      features: emergencyFeature
    })

    this.renderMotion(geojson)
  }

  renderMotion(geojson) {
    const pointCollection = {
      type: 'FeatureCollection',
      features: []
    }

    const lineCollection = {
      type: 'FeatureCollection',
      features: []
    }

    const arrowHeadCollection = {
      type: 'FeatureCollection',
      features: []
    }

    const now = moment.utc()

    geojson.features.forEach((f) => {
      try {
        if (
          !(
            typeof f.properties.tags === 'object' &&
            typeof f.properties.tags.TIME_MOT_LOC === 'object'
          )
        )
          return

        const motion = f.properties.tags.TIME_MOT_LOC
        const darkenedColor = colord(f.properties['line-color']).darken(0.25).toHex()

        // console.log(motion)

        if (motion.speed < 1) return

        motion.positions.forEach((p, i) => {
          // Points
          pointCollection.features.push({
            type: 'Feature',
            geometry: { type: 'Point', coordinates: p },
            properties: {
              id: `${f.properties.id}-${i}`,
              'circle-color': f.properties['line-color'],
              'circle-stroke-color': darkenedColor
            }
          })

          // Lines
          const po = point(p)
          const direction = (motion.direction + 180) % 360
          // Convert knots to mph
          const speedMph = motion.speed * 1.15078

          const age = moment.duration(now.diff(moment.utc(f.properties.issued_at))).asHours()

          let distance = age * speedMph

          // Have a cut off for distance?
          if (distance > 10) {
            distance = 10
          }
          else if(distance < 3) {
            distance = 3;
          }

          const dest = destination(po, distance, direction, { units: 'miles' })

          const lineCoords = [p, dest.geometry.coordinates]

          lineCollection.features.push({
            type: 'Feature',
            geometry: { type: 'LineString', coordinates: lineCoords },
            properties: {
              id: `${f.properties.id}-${i}`,
              'line-color': f.properties['line-color']
            }
          })

          // Arrow heads
          const size = 0.5;
          const sideSize = 0.33;
          const startOfArrowHead = destination(po, distance - size, direction, { units: 'miles' });
          const leftSide = destination(startOfArrowHead, sideSize, direction-90, { units: 'miles' }).geometry.coordinates;
          const rightSide = destination(startOfArrowHead, sideSize, direction+90, { units: 'miles' }).geometry.coordinates;
          const arrowHeadCoords = [dest.geometry.coordinates, leftSide, rightSide, dest.geometry.coordinates];

          arrowHeadCollection.features.push({
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [arrowHeadCoords] },
            properties: {
              id: `${f.properties.id}-${i}`,
              'fill-color': f.properties['line-color']
            }
          })
        })
      } catch (e) {
        console.error('Failed to generate warning motion', f, e)
      }
    })

    this.map.getSource(this.motionPointsSourceId).setData(pointCollection)
    this.map.getSource(this.motionPathSourceId).setData(lineCollection)
    this.map.getSource(this.motionArrowHeadSourceId).setData(arrowHeadCollection)
  }

  async fetchWarningFromArchive(id) {
    try {
      const geojson = await api.instance().get(`/warnings/archive/${id}.geojson`);
      const feature = this.applyPropertiesToFeature(geojson);

      return this.openWarningModal(feature);
    } catch (error) {
      console.log(error)
      
      this.openNotFoundModal();
    }
  }

  setRealtimeUpdates(b) {
    this.realtimeUpdatesEnabled = b;
  }

  fitBounds(feature) {
    const box = bbox(feature.geometry)

    const sw = new mapboxgl.LngLat(box[0], box[1]);
    const ne = new mapboxgl.LngLat(box[2], box[3]);
    const llb = new mapboxgl.LngLatBounds(sw, ne);

    // Include the motion points in the bounding box
    // As sometimes they can be a little far outside of the polygon
    if(feature.properties.tags?.TIME_MOT_LOC !== undefined) {
      feature.properties.tags?.TIME_MOT_LOC?.positions?.forEach(p => {
        llb.extend(p);
      });
    }

    this.map.fitBounds(llb, {
      padding: window.innerWidth / 8,
      duration: 0
    })
  }
}
