import { useRecordingStore } from '../../stores/recording';
import html2canvas from 'html2canvas';

const MAX_MP4_LENGTH = 10 * 1000;
const FPS = 25;
const OVERLAY_WAIT_TIME = 1000; // Wait 1 second for overlays to appear

export default class Mp4Recorder {
  constructor(map) {
    this.map = map;
    this.canvas = map.getCanvas();
    this.recording = false;
    this.stopRecordingTimeout = null;
    this.recordingStore = useRecordingStore();
    this.worker = null;
    this.lastFrameTime = 0;
    this.captureCanvas = null;
    this.captureContext = null;

    this.calculateOutputDimensions();
  }


  calculateOutputDimensions() {
    const maxWidth = 1280;  // Maximum width for any device
    const maxHeight = 720;  // Maximum height for any device
    const minWidth = 480;   // Minimum width to ensure quality
    const minHeight = 360;  // Minimum height to ensure quality

    let width = this.canvas.width;
    let height = this.canvas.height;

    // Calculate aspect ratio
    const aspectRatio = width / height;

    // Scale down if exceeds maximum dimensions
    if (width > maxWidth || height > maxHeight) {
      if (width / maxWidth > height / maxHeight) {
        width = maxWidth;
        height = Math.round(width / aspectRatio);
      } else {
        height = maxHeight;
        width = Math.round(height * aspectRatio);
      }
    }

    // Scale up if below minimum dimensions
    if (width < minWidth || height < minHeight) {
      if (width / minWidth < height / minHeight) {
        width = minWidth;
        height = Math.round(width / aspectRatio);
      } else {
        height = minHeight;
        width = Math.round(height * aspectRatio);
      }
    }

    // Apply the 0.9 scaling factor
    width = Math.floor(width * 0.9);
    height = Math.floor(height * 0.9);

    // Ensure dimensions are even
    width = width % 2 === 0 ? width : width - 1;
    height = height % 2 === 0 ? height : height - 1;

    this.output = {
      width: width,
      height: height
    };
  }

  async record() {
    this.recording = true;
    this.recordingStore.startScreenshot();

    // Wait for overlays to appear
    await new Promise(resolve => setTimeout(resolve, OVERLAY_WAIT_TIME));

    this.stopRecordingTimeout = setTimeout(() => this.stop(), MAX_MP4_LENGTH);

    this.worker = new Worker(new URL('./recordingWorker.js', import.meta.url), { type: 'module' });

    const overlays = document.querySelectorAll('.map-overlay');
    let overlay = null;
    let overlayImageData = null;

    if (overlays.length > 0) {
      overlay = overlays[0];
      const overlayCanvas = await html2canvas(overlay);
      overlayImageData = overlayCanvas.getContext('2d').getImageData(0, 0, overlayCanvas.width, overlayCanvas.height);
    }

    // Load and prepare the watermark
    const watermark = await this.loadWatermark('ww-watermark.png');
    const watermarkImageData = await this.createWatermarkImageData(watermark);

    // Create a new canvas for capturing frames
    this.captureCanvas = document.createElement('canvas');
    this.captureCanvas.width = this.canvas.width;
    this.captureCanvas.height = this.canvas.height;
    // Use willReadFrequently for better performance with frequent getImageData calls
    this.captureContext = this.captureCanvas.getContext('2d', { willReadFrequently: true });

    this.worker.postMessage({
      type: 'start',
      canvasWidth: this.canvas.width,
      canvasHeight: this.canvas.height,
      outputWidth: this.output.width,
      outputHeight: this.output.height,
      fps: FPS,
      overlay: overlay ? {
        left: overlay.offsetLeft,
        top: overlay.offsetTop,
        width: overlayImageData.width,
        height: overlayImageData.height,
        data: overlayImageData.data
      } : null,
      watermark: {
        width: watermarkImageData.width,
        height: watermarkImageData.height,
        data: watermarkImageData.data
      }
    }, [
      ...(overlayImageData ? [overlayImageData.data.buffer] : []),
      watermarkImageData.data.buffer
    ]);

    this.worker.onmessage = (event) => {
      if (event.data.type === 'done') {
        this.downloadBlob(event.data.blob, event.data.filename);
      }
    };

    this.lastFrameTime = performance.now();
    this.renderFrame();
  }

  renderFrame() {
    if (!this.recording) return;

    const now = performance.now();
    const elapsed = now - this.lastFrameTime;

    if (elapsed >= (1000 / FPS)) {
      try {
        // Draw the map canvas onto our capture canvas
        this.captureContext.drawImage(this.canvas, 0, 0);

        const imageData = this.captureContext.getImageData(0, 0, this.captureCanvas.width, this.captureCanvas.height);
        this.worker.postMessage({ type: 'frame', imageData }, [imageData.data.buffer]);

        this.lastFrameTime = now;
      } catch (error) {
        console.error('Error capturing frame:', error);
        this.stop();
        return;
      }
    }

    requestAnimationFrame(() => this.renderFrame());
  }

  stop() {
    this.recording = false;
    this.recordingStore.stopScreenshot();
    this.recordingStore.stop();
    if (this.stopRecordingTimeout != null) {
      clearTimeout(this.stopRecordingTimeout);
      this.stopRecordingTimeout = null;
    }
    if (this.worker) {
      this.worker.postMessage({ type: 'stop' });
    }
    // Clean up capture canvas
    this.captureCanvas = null;
    this.captureContext = null;
  }

  async downloadBlob(blob, filename) {
    navigator._download.blob(blob, 'video/mp4');
  }

  async loadWatermark(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  async createWatermarkImageData(watermarkImage) {
    const canvas = new OffscreenCanvas(150, 75);
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    ctx.drawImage(watermarkImage, 0, 0, 150, 75);
    return ctx.getImageData(0, 0, 150, 75);
  }
}
