<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<template>
  <div class="absolute top-3 left-3 sm:left-1/2 sm:-translate-x-1/2 w-[100] h-[50px] z-20 px-4 bg-ww-slate-100 rounded-lg flex select-none">
    <img src="/weatherwise-logo.svg" class="h-3/4 object-contain flex-initial self-center" alt="WeatherWise Logo">
    <div class="font-[Outfit] text-lg items-center flex flex-1 ml-3 hidden sm:flex">
      <span class="text-[#17274F] font-bold drop-shadow-sm">Weather</span><span class="text-[#F6B91A] font-bold drop-shadow-sm">Wise</span>
    </div>

    <div style="border-right: 1px solid #CCC;" class="my-2 mx-4"></div>

    <ul class="flex flex-wrap text-sm font-medium text-center text-gray-700 sm:hidden" style="margin-top: 7px">
      <li>
        <a v-if="appStore.mode === 'RADAR'" @click.prevent="onMobileMenuItemClick($event)" href="#" class="inline-block px-4 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800 rounded-lg active"><Icon icon="game-icons:radar-sweep" class="inline size-6" /><span class="font-bold ml-2">Radar</span><Icon :icon="menuOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="size-4 inline ml-3" /></a>
        <a v-if="appStore.mode === 'OUTLOOKS'" @click.prevent="onMobileMenuItemClick($event)" href="#" class="inline-block px-4 py-2 font-bold text-white bg-blue-600 hover:bg-blue-800 rounded-lg active"><Icon icon="arcticons:forecaster" class="inline size-6" /><span class="font-bold ml-2">Outlooks</span><Icon :icon="menuOpen ? 'gravity-ui:chevron-up-wide' : 'gravity-ui:chevron-down-wide'" class="size-4 inline ml-3" /></a>
      </li>
    </ul>

    <ul class="flex flex-wrap text-sm font-medium text-center text-gray-700 hidden sm:flex" style="margin-top: 7px">
      <li class="">
        <a href="#" @click.prevent="onModeClick($event, 'RADAR')" class="inline-block px-4 py-2 font-bold rounded-lg" :class="{
          'text-white bg-blue-600 active': appStore.mode === 'RADAR',
          'hover:text-gray-950 hover:bg-gray-200': appStore.mode !== 'RADAR'
        }"><Icon icon="game-icons:radar-sweep" class="inline size-6" /><span class="font-bold ml-2">Radar</span></a>
      </li>
      <li class="ms-2">
        <a href="#" @click.prevent="onModeClick($event, 'OUTLOOKS')" class="inline-block px-4 py-2 font-bold rounded-lg" :class="{
          'text-white bg-blue-600 active': appStore.mode === 'OUTLOOKS',
          'hover:text-gray-950 hover:bg-gray-200': appStore.mode !== 'OUTLOOKS'
        }"><Icon icon="arcticons:forecaster" class="inline mr-2 size-6" />Outlooks</a>
      </li>
    </ul>
  </div>

  <Transition name="slide-fade">
    <div v-if="menuOpen" v-on-click-outside="onMenuOutsideClick" id="mobile-menu" class="absolute top-16 left-0 px-3 sm:left-1/2 sm:-translate-x-1/2 w-full z-30 select-none">
      <div class="bg-white rounded-lg">
        <ul class="text-sm font-medium text-center text-gray-700 p-3" style="margin-top: 7px">
          <li>
            <a href="#" @click.prevent="onModeClick($event, 'RADAR')" class="block px-4 py-2 font-bold rounded-lg" :class="{
              'text-white bg-blue-600 active': appStore.mode === 'RADAR',
              'hover:text-gray-950 hover:bg-gray-200': appStore.mode !== 'RADAR'
            }"><Icon icon="game-icons:radar-sweep" class="inline size-6" /><span class="font-bold ml-2">Radar</span></a>
          </li>
          <li class="mt-3">
            <a href="#" @click.prevent="onModeClick($event, 'OUTLOOKS')" class="block px-4 py-2 font-bold rounded-lg" :class="{
              'text-white bg-blue-600 active': appStore.mode === 'OUTLOOKS',
              'hover:text-gray-950 hover:bg-gray-200': appStore.mode !== 'OUTLOOKS'
            }"><Icon icon="arcticons:forecaster" class="inline mr-2 size-6" />Outlooks</a>
          </li>
        </ul>
      </div>
    </div>
  </Transition>
</template>

<script>
import { Icon } from '@iconify/vue'
import { useAppStore } from '@/stores/app'

export default {
  name: 'AppLogo',
  components: {
    Icon
  },
  data() {
    return {
      menuOpen: false
    }
  },
  computed: {
    appStore() {
      return useAppStore()
    }
  },
  methods: {
    onModeClick(e, mode) {
      this.menuOpen = false;

      this.appStore.setMode(mode);
    },
    onMobileMenuItemClick(e) {
      this.menuOpen = !this.menuOpen;
    },
    onMenuOutsideClick() {
      // Hack to deal with mobile toggle button not working...
      setTimeout(() => {
        this.menuOpen = false;
      }, 10);
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}
</style>