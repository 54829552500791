import { point, bearing, destination } from '@turf/turf'
import moment from 'moment'

import { stopPropagation } from '../../tools/mapbox-map'

import socket from '../../logic/Socket'
import api from '../Api'

export default class Lightning {
  constructor(map) {
    this.map = map

    this.pointsSourceId = 'radar-lightning-strikes-points-source'
    this.pointsLayerId = 'radar-lightning-strikes-points-layer'

    // 15 mins... ?
    this.MAX_STRIKE_AGE = 15 * 60;

    this.collection = {
      type: 'FeatureCollection',
      features: []
    }

    setInterval(() => {
      const nowEpoch = moment.utc().unix();
      this.collection.features = this.collection.features.filter((f) => {
        return (nowEpoch - f.properties.at) < this.MAX_STRIKE_AGE;
      }).map((f) => {
        let opacity = 1 - ((nowEpoch - f.properties.at) / this.MAX_STRIKE_AGE);
        if(opacity < 0.1) opacity = 0.1;
        f.properties['icon-opacity'] = opacity;
        return f;
      });

      this.draw();
    }, 60 * 1000);

    // Add source/layer for points
    map.addSource(this.pointsSourceId, {
      type: 'geojson',
      data: this.collection
    })

    map.addLayer({
      id: this.pointsLayerId,
      type: 'symbol',
      source: this.pointsSourceId,
      layout: {
        'icon-image': ["get", "icon-image"],
        'icon-size': 0.05,
        'icon-padding': 0,
        'icon-pitch-alignment': 'map',
        'symbol-sort-key': ["-", 1, ["get", "icon-opacity"]]
      },
      paint: {
        'icon-opacity': ["get", "icon-opacity"]
      }
    })

    // const pointOnClick = stopPropagation((e) => {
    //   if(e.features.length == 0) return;
    //   console.log(e.features)
    // });
    // map.on('click', this.pointsLayerId, pointOnClick)
  }

  strikesToFeatures(strikes) {
    const nowEpoch = moment.utc().unix();

    const features = strikes.filter(s => {
      return (nowEpoch - s[2]) < this.MAX_STRIKE_AGE;
    }).map((t, i) => {
      let opacity = 1 - ((nowEpoch - t[2]) / this.MAX_STRIKE_AGE);
      if(opacity < 0.1) opacity = 0.1;

      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: t },
        properties: {
          id: `${Math.random()}`,
          at: t[2],
          'icon-image': 'radar-lightning-strikes-strike',
          'icon-opacity': opacity
        }
      }
    });

    return features;
  }

  push(features) {
    for(const feature of features) {
      this.collection.features.push(feature)
    }

    return this;
  }

  draw() {
    this.map.getSource(this.pointsSourceId).setData(this.collection)
  }

  async load(towerId) {
    if(! this.map.hasImage('radar-lightning-strikes-strike')) {
      await new Promise((resolve, reject) => {
         this.map.loadImage(
            '/radar/lightning/strike.png',
            (error, image) => {
              if(error) return reject(error);

              this.map.addImage('radar-lightning-strikes-strike', image);

              resolve();
            })
      });
    }

    // Load past strikes for some interval
    try {
      const lightning = await api.instance().get(`/satellite/radar/${towerId}/active.json`);

      const features = this.strikesToFeatures(lightning.strikes);

      this.push(features).draw();
    }
    catch(e) {
      console.error(`Failed to fetch lightning data for: ${towerId}`, e);
    }

    const room = `radar-lightning-strikes:${towerId}`
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Radar lightning strike', room, data)

      const features = this.strikesToFeatures(data.strikes);

      this.push(features).draw();
    })
  }

  clear(towerId) {
    this.collection.features = [];
    this.draw();

    const room = `radar-lightning-strikes:${towerId}`
    socket.roomLeave(room)
    socket.removeAllListeners(room)
  }

  show() {
    for(const layerId of [this.pointsLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of [this.pointsLayerId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }
}
