import { useModal, useModalSlot } from 'vue-final-modal'

import SimpleModal from './Templates/Simple.vue'
import CenteredModal from './Templates/Centered.vue'

import DisclaimerModal from './Disclaimer.vue'
import CreditsModal from './Credits.vue'
import SupportModal from './Support.vue'
import ShareModal from './Share.vue'

class Helper {
  disclaimer() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Disclaimer',
      },
      slots: {
        default: useModalSlot({
          component: DisclaimerModal
        })
      },
    })
  }

  credits() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Credits',
      },
      slots: {
        default: useModalSlot({
          component: CreditsModal
        })
      },
    })
  }

  support() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Support',
      },
      slots: {
        default: useModalSlot({
          component: SupportModal
        })
      },
    })
  }

  share() {
    return useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'Share',
      },
      slots: {
        default: useModalSlot({
          component: ShareModal
        })
      },
    })
  }
}

export default new Helper()
