export default {
  enabled: import.meta.env.PROD,
  accessToken: '4f77d36d2d5a47679f065769618623b6',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.PROD ? 'prod' : 'dev',
    client: {
      javascript: {
        code_version: '1.0.0'
      }
    }
  }
}
