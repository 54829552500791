export const stopPropagation = (fn) => {
	return (...args) => {
		const e = args[0];

		if(e.ww_stopPropagation_ === true) return;

		e.ww_stopPropagation_ = true;

		return fn.apply(this, args);
	};
};

export const debounce = (func, timeout) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
};